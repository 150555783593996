import React from 'react'
import { Text, type TextProps } from '../../text'
import { type ActionStylingProps } from './actionStyles'

type ActionTextProps = TextProps & ActionStylingProps

export const ActionText = ({
  transparent,
  look,
  feel,
  ...props
}: ActionTextProps) => {
  const styles: TextProps = {}

  if (!transparent) {
    if (look === 'button') {
      styles.color =
        feel === 'primary'
          ? // The background will be primary so use white
            'white'
          : // The background will be the default background color and text should
            // be primary.
            'primary'
      styles.variant = 'input'
    } else {
      styles.color = 'primary'
    }
  }

  return <Text {...styles} {...props} />
}
