/**
 * Safely call a getter function that could throw. If it throws, `undefined` is
 * returned instead.
 */
export function safeGetter<T>(item: T, getter: (item: T) => unknown) {
  try {
    return getter(item)
  } catch (e) {
    console.warn('Unable to get value for', item)
    return undefined
  }
}
