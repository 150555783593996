import React from 'react'

export function mergeRefs<T = any>(
  refs: Array<React.MutableRefObject<T> | React.LegacyRef<T>>,
): React.RefCallback<T> {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value)
      } else if (ref != null) {
        ;(ref as React.MutableRefObject<T | null>).current = value
      }
    })
  }
}

/**
 * Determine the name of the given React component.
 */
export const getComponentName = (component: React.ReactElement) => {
  if (component.props.page) {
    return component.props.page
  } else {
    const type: any = component.type
    switch (typeof type) {
      case 'function':
      default:
        if (type.displayName) return type.displayName
        else return type.name
    }
  }
}
