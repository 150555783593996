const sizes = {
  0: 0,
  border: 1,
  xxs: 4,
  xs: 8,
  s: 16,
  m: 24,
  l: 32,
  l2: 34,
  xl: 48,
}

export default sizes
