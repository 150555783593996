import React from 'react'
import { LayoutRectangle, StyleSheet } from 'react-native'
import * as SplashScreen from 'expo-splash-screen'

import {
  Box,
  Splash,
  ActionBase,
  ErrorView,
  BodyText,
  BoxProps,
  StyleTransition,
  TransitionConfig,
} from '~/components'
import { DarkThemeProvider } from '~/theme'
import { env } from '~/env'
import { type AnalyticsService } from '~/services/analytics'

const NO_USER_MESSAGE =
  'Oh No! It looks like we were unable to load your account. ' +
  `Please contact ${env.supportEmail} so we can help complete your account creation.`

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    flex: 1,
    position: 'relative',
  },
  fadeIn: {
    position: 'absolute',
    top: '60%',
    width: '100%',
    alignItems: 'center',
  },
  opacityTransition: {
    alignItems: 'center',
  },
  error: {
    flex: 1,
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loginButton: {
    width: 250,
  },
})

interface LoginProps extends BoxProps {
  onLogin: () => Promise<any>
  ready: boolean
  loading: boolean
  authenticated: boolean
  userNotFound?: boolean
  loginError?: boolean
  animationDelay?: number
  animationDuration?: number
  disableSplashAnimation?: boolean
}

/**
 * `<Login>` page shows the Splash screen and a login button.
 * The actual login form is displayed in a browser window after
 * clicking the login button. That form is hosted by Auth0 and
 * can be configured through the Auth0 console.
 */
export const Login = ({
  onLogin,
  ready,
  loading,
  authenticated,
  userNotFound = false,
  loginError,
  animationDelay = 250,
  animationDuration = 250,
  disableSplashAnimation,
  style,
  ...rest
}: LoginProps) => {
  const emptyDimensions: LayoutRectangle = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  }
  const [dimensions, setDimensions] = React.useState(emptyDimensions)

  const showButton = ready && !authenticated
  const enableButton = ready && !loading

  React.useEffect(() => {
    /* istanbul ignore next: this is really just to prevent error logs */
    SplashScreen.hideAsync().catch(() => {
      // Ignore errors hiding the splash screen. These occur when
      // we hide and show the login multiple times in a session.
    })
  }, [])

  const w = dimensions.width || 0
  const h = dimensions.height || 0
  const diameter = Math.sqrt(w * w + h * h)
  const top = h / 2 - diameter / 2
  const left = w / 2 - diameter / 2

  const fadeIn = React.useMemo(
    () =>
      [
        {
          property: 'opacity',
          duration: animationDuration,
        },
      ] as TransitionConfig[],
    [animationDuration],
  )

  const opacityTransition = React.useMemo(
    () =>
      [
        {
          property: 'opacity',
          duration: animationDuration,
          value: (p) => {
            'worklet'
            return 0.5 + p * 0.5
          },
        },
      ] as TransitionConfig[],
    [animationDuration],
  )

  return (
    <Box
      testID="Login"
      style={[styles.container, style]}
      onLayout={(e) => setDimensions(e.nativeEvent.layout)}
      {...rest}
    >
      <Splash
        position="absolute"
        style={{
          width: diameter,
          height: diameter,
          borderRadius: (diameter || /*istanbul ignore next*/ 1) / 2,
          transform: [{ translateY: top }, { translateX: left }],
        }}
        state={
          /* istanbul ignore next: Animation loop causes issues during testing */
          disableSplashAnimation ? 'stopped' : loading ? 'playing' : 'stopped'
        }
        animationDuration={
          disableSplashAnimation ? 0 : /*istanbul ignore next*/ undefined
        }
        logoPosition={userNotFound ? -100 : '35%'}
      />
      <StyleTransition
        // We use two different opacity transitions here because we want to
        // transition between 3 states (hidden, disabled, enabled) but
        // StyleTransition only supports 2 state transitions.
        // TODO Update StyleTransition to handle multiple states
        state={showButton}
        animateEntrance={false}
        persistent={false}
        disableAnimations={disableSplashAnimation}
        transitions={fadeIn}
        style={styles.fadeIn}
      >
        <StyleTransition
          testID="ButtonWrapper"
          state={enableButton}
          disableAnimations={disableSplashAnimation}
          transitions={opacityTransition}
          style={styles.opacityTransition}
        >
          {/* Use an ActionBase because the router is not available yet */}
          <ActionBase
            testID="LoginButton"
            disabled={loading || authenticated}
            style={styles.loginButton}
            onPress={() => onLogin()}
          >
            Log In
          </ActionBase>
          {loginError && (
            <DarkThemeProvider>
              <BodyText mt="s">
                Hmmm...something went wrong trying to log you in. Please give it
                another try.
              </BodyText>
            </DarkThemeProvider>
          )}
        </StyleTransition>
      </StyleTransition>
      {userNotFound && (
        <ErrorView
          title="User Not Found"
          message={NO_USER_MESSAGE}
          showHelp={false}
          dark
          style={styles.error}
        />
      )}
    </Box>
  )
}

export interface LoginConnectedProps extends LoginProps {
  trackPageview: AnalyticsService['screen']
  /**
   * Allows configuring the global Document object during testing.
   */
  doc?: Document
}

/**
 * `<LoginConnected>` connects the Login
 * component with the rest of the app (ie. routing, services, store, etc.).
 */
export const LoginConnected = ({
  onLogin,
  ready,
  loading,
  authenticated,
  userNotFound,
  trackPageview,
  animationDelay,
  animationDuration,
  disableSplashAnimation,
  doc = window.document,
  ...rest
}: LoginConnectedProps) => {
  const [loginError, setLoginError] = React.useState(false)
  const handleLogin = async () => {
    try {
      await onLogin()
    } catch (e) {
      // This is only intended to catch unexpected errors caused by bugs as
      // opposed to authentication falures such as Auth0 service being down or
      // returning an error code.
      console.error('[Login] an error occured during login:', e)
      setLoginError(true)
    }
  }

  React.useEffect(() => {
    if (!ready && loading) {
      trackPageview('Splash Screen', {
        path: '/',
        search: '',
      })
    }
  }, [loading, ready, trackPageview])

  React.useEffect(() => {
    if (ready && !loading && userNotFound) {
      trackPageview('User Not Found', {
        path: '/user-not-found',
        search: '',
      })
    }
  }, [loading, ready, trackPageview, userNotFound])

  React.useEffect(() => {
    if (ready && !loading && !userNotFound && !authenticated && !loginError) {
      trackPageview('Log In', {
        path: '/login',
        search: '',
      })
    }
  }, [authenticated, loading, loginError, ready, trackPageview, userNotFound])

  return (
    <Login
      onLogin={handleLogin}
      ready={ready}
      loading={loading}
      authenticated={authenticated}
      userNotFound={userNotFound}
      loginError={loginError}
      animationDuration={animationDuration}
      animationDelay={animationDelay}
      disableSplashAnimation={disableSplashAnimation}
      {...rest}
    />
  )
}
