import React from 'react'

/**
 * Determine if this is the component's first render cycle.
 */
export function useIsFirstRender() {
  const initialized = React.useRef(false)
  const isFirstCall = !initialized.current
  initialized.current = true
  return isFirstCall
}
