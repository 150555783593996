import { NormalizedTransitionConfig } from './types'

/**
 * Determine if a given animation property name is related to a CSS transform or
 * is a standard CSS property.
 */
export function isTransformProperty(name: string) {
  switch (name) {
    case 'scale':
    case 'scaleX':
    case 'scaleY':
    case 'rotate':
    case 'rotateX':
    case 'rotateY':
    case 'rotateZ':
    case 'skewX':
    case 'skewY':
    case 'translate':
    case 'translateX':
    case 'translateY':
    case 'perspective':
    case 'matrix':
      return true
    default:
      return false
  }
}

export function getLongestTransition(
  normalizedTransitions: NormalizedTransitionConfig[],
) {
  return normalizedTransitions.length > 0
    ? normalizedTransitions.reduce((acc, curr) => {
        if (!acc) return curr
        const currDuration = curr.duration + (curr.delay || 0)
        const accDuration = acc?.duration + (acc?.delay || 0)
        return currDuration > accDuration ? curr : acc
      })
    : undefined
}
