const timings = {
  // All times in ms
  xs: 150,
  s: 250,
  m: 500,
  l: 1000,
  xl: 1500,
}

export default timings
