/**
 * This file contains functions that can be used in conjuncture with
 * `@shopify/restyle` to create components with responsive props
 * attached to our theme (like `backgroundColor` and `margin`).
 *
 * @see https://shopify.github.io/restyle/
 */

// TODO Better name for this file. Break it into multiple files?

import {
  type ResponsiveValue as RestyleResponsiveValue,
  PropValue,
  useResponsiveProp as useRestyleResponsiveProp,
} from '@shopify/restyle'
import { type LightTheme } from '../themes'

/**
 *  Convert a union of possible values into their responsive equivalents.
 *
 * ```ts
 * type Foo = ResponsiveValue<'primary' | 'secondary'>
 * // Foo can now be 'primary' or { phone: 'primary' } etc
 * ````
 */
export type ResponsiveValue<ValuesUnion> = RestyleResponsiveValue<
  ValuesUnion,
  LightTheme['breakpoints']
>

/**
 * A type helper for creating an interface with responsive style properties.
 *
 * @example
 *
 * ```ts
 * type Borders = ResponsiveThemeProps<'borderWidth' | 'borderTopWidth', 's' | 'm'>
 *
 * // Results in an interface similar to:
 * interface Borders {
 *   borderWidth?    : ResponsiveValue<'s' | 'm', Theme['breakpoints']>
 *   borderTopWidth? : ResponsiveValue<'s' | 'm', Theme['breakpoints']>
 * }
 * ```
 */
export type ResponsiveThemeProps<
  /**
   * These will become the keys on the output interface
   */
  Props extends string | number | symbol,
  /**
   * The type of values allowed for each key
   */
  Values,
> = Partial<Record<Props, ResponsiveValue<Values>>>

export type UseResponsiveProp = <TVal extends PropValue>(
  propValue: ResponsiveValue<TVal>,
) => TVal | undefined
/**
 * Get a responsive prop value from the theme.
 */
export const useResponsiveProp = useRestyleResponsiveProp as UseResponsiveProp

type ThemeValue = any

interface TransfromFunctionArgs {
  value: ThemeValue
  theme: Record<string, Record<string, ThemeValue> | boolean>
}

/**
 * A helper to create a theme value lookup function that checks against multiple
 * keys in the theme.
 *
 * @example
 * ```ts
 * const theme = {
 *   foo: {
 *     light: 'white',
 *   },
 *   bar:
 *     dark: 'black
 *   }
 * }
 *
 * const transform = multiKeyTransformFactory(['foo', 'bar'])
 *
 * const color = transform({value: 'dark', theme})
 * // color -> 'black'
 * ```
 */
export const multiKeyTransformFactory = (keys: string[]) => {
  return (
    { value, theme }: TransfromFunctionArgs,
    /**
     * Prevent warnings if the theme key does not exist in the theme.
     */
    silent = false,
  ) => {
    if (value == null) return undefined
    const key = keys.find((k) => !!theme[k][value])
    if (key) return theme[key][value]
    if (!silent)
      console.error(
        `[theme/variants] ${String(
          value,
        )} is not defined in the theme under any of the following keys:`,
        keys,
      )
    return undefined
  }
}

/**
 * A helper to create the theme value lookup function that will fallback to the
 * value passed if it is not in the theme.
 */
export const themeOrValue = (key: string) => {
  return ({ value, theme }: TransfromFunctionArgs) => {
    if (value == null) return value
    return theme[key][value] || value
  }
}
