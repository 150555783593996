import { AccessibilityInfo, Platform } from 'react-native'
import { createURL } from 'expo-linking'

import manifest from '~/../app.json'
import pkg from '~/../package.json'

export type LogStorage = 'MEMORY' | 'SQLITE' | 'NONE'

let disableAnimations: boolean

/**
 * The `env` object allows access to the
 * environment variables from the `.env` files,
 * browser location and cookies. It also
 * allows us to coerce the type of environment
 * variables which are strings by default.
 *
 * NOTE: When adding new environment variables,
 * you'll need to add them to all of the following
 * files:
 * - `src/env.ts` (this file)
 * - `src/@types/env.d.ts`
 * - `.env` (and other associated environment files)
 */
/* istanbul ignore next: not much here */
export const env = {
  async init() {
    disableAnimations = await AccessibilityInfo.isReduceMotionEnabled()
  },

  /**
   * Animations should be disable during testing or if the user prefers reduced
   * motion.
   */
  get disableAnimations() {
    return process.env.EXPO_PUBLIC_APP_ENV === 'test' || disableAnimations
  },

  /**
   * The native version number. This is the semver version listed in the
   * `app.json` file. It is used to indicate which OTA updates are compatible
   * with which native clients. When the app gets built, this version is
   * compiled into the app and is used by the updates code to request OTA
   * updates that use the same client version.
   *
   * To get the native buildNumber/versionCode use
   * the `nativeBuildVersion` function from `expo-application`.
   */
  get appVersion() {
    return Platform.OS === 'web' ? 'web' : manifest.expo.version
  },

  /**
   * The JS version number. This is the semver version listed in the
   * `package.json` file. It is used to tell us exactly which version of the JS is
   * running on the current client.
   */
  get jsVersion() {
    return pkg.version
  },

  /**
   * Get the URL scheme used for this version of the app.
   */
  get schema() {
    return createURL('')
  },

  get verbose() {
    return process.env.EXPO_PUBLIC_VERBOSE === 'true'
  },

  /**
   * Which log storage mechanism to use
   */
  get logStorage() {
    return String(
      process.env.EXPO_PUBLIC_LOG_STORAGE || 'NONE',
    ).toUpperCase() as LogStorage
  },

  get environment() {
    return process.env.EXPO_PUBLIC_APP_ENV
  },

  get production() {
    return (
      process.env.EXPO_PUBLIC_APP_ENV === 'production' ||
      process.env.EXPO_PUBLIC_APP_ENV === 'prod'
    )
  },

  get stage() {
    return process.env.EXPO_PUBLIC_APP_ENV === 'stage'
  },

  get test() {
    return process.env.EXPO_PUBLIC_APP_ENV === 'test'
  },

  get development() {
    return (
      process.env.EXPO_PUBLIC_APP_ENV === 'development' ||
      process.env.EXPO_PUBLIC_APP_ENV === 'dev' ||
      process.env.EXPO_PUBLIC_APP_ENV === 'mock'
    )
  },

  get mocks() {
    return process.env.EXPO_PUBLIC_MOCKS === 'true'
  },

  get analyticsEnabled() {
    return process.env.EXPO_PUBLIC_ANALYTICS_ENABLED === 'true'
  },

  supportEmail: process.env.EXPO_PUBLIC_SUPPORT_EMAIL,
  supportURL: process.env.EXPO_PUBLIC_SUPPORT_URL,
  salesEmail: process.env.EXPO_PUBLIC_SALES_EMAIL,
  authDomain: process.env.EXPO_PUBLIC_AUTH_DOMAIN,
  authClientId: process.env.EXPO_PUBLIC_AUTH_CLIENT_ID,
  reportGraphAPI: process.env.EXPO_PUBLIC_REPORT_GRAPH_API,
  reportAPIAdminSecret: process.env.EXPO_PUBLIC_REPORT_API_ADMIN_SECRET,
  segmentWriteKey:
    Platform.OS === 'web'
      ? process.env.EXPO_PUBLIC_SEGMENT_WRITE_KEY_WEB
      : process.env.EXPO_PUBLIC_SEGMENT_WRITE_KEY_NATIVE,

  log() {
    const color = 'color: #d3dd2d; font-weight: bold;'
    if (!env.test)
      console.log('%c[ENV]', color, 'environment:', env.environment)
    if (!env.production && env.verbose) {
      for (const key in env) {
        const blacklist = ['log', 'environment', 'reportAPIAdminSecret']
        if (blacklist.indexOf(key) === -1) {
          console.log('%c[ENV]', color, `${key}:`, env[key])
        }
      }
      if (env.reportAPIAdminSecret) {
        const mask = env.reportAPIAdminSecret.substring(0, 5)
        console.log('[ENV]', color, `reportAPIAdminSecret: ${mask}***`)
      }
    }
  },
}
