import { bezierFactory } from './beziers'

const animation = {
  // You can create spring like CSS easing animations with this:
  // https://linear-easing-generator.netlify.app/
  spring: {
    stiff: {
      stiffness: 130,
      mass: 1,
      damping: 15,
    },
  },
  easing: {
    linear: 'linear',
    ...bezierFactory((c) => `cubic-bezier(${c.join(',')})`),
  },
}

type ThemeAnimation = typeof animation
type Easing = ThemeAnimation['easing']
type ThemeEasingGroup = keyof Easing
type ThemeEasingDirection = keyof Easing[ThemeEasingGroup]
export type ThemeEasing =
  | `${ThemeEasingGroup}.${ThemeEasingDirection}`
  | 'linear'

export default animation
