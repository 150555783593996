import React from 'react'
import { Text, TextProps } from './Text'

/**
 * A base type for our Text2 specializations which doesn't include the `variant`
 * prop because we shouldn't specify both the Specialization and the Variant.
 * For example, `<Heading variant="body" />` makes no sense.
 */
export type TextWithoutVariantProps = Omit<TextProps, 'variant'>

export const SubTitle = React.forwardRef<typeof Text, TextWithoutVariantProps>(
  (props, ref) => <Text variant="subtitle" {...props} ref={ref} />,
)

export const InfoText = React.forwardRef<typeof Text, TextWithoutVariantProps>(
  (props, ref) => <Text variant="info" color="primary" {...props} ref={ref} />,
)

/**
 * A text variant when you want to write an aside like legalese.
 */
export const SubScript = React.forwardRef<typeof Text, TextWithoutVariantProps>(
  (props, ref) => (
    <Text variant="subscript" color="faded" {...props} ref={ref} />
  ),
)

/**
 * Text variant to use for labelling metric/goal/progress values.
 */
export const ValueLabel = React.forwardRef<
  typeof Text,
  TextWithoutVariantProps
>((props, ref) => (
  <Text variant="value-label" color="faded" {...props} ref={ref} />
))

/**
 * Text variant to use for progress call outs.
 */
export const ValueText = React.forwardRef<typeof Text, TextWithoutVariantProps>(
  (props, ref) => <Text variant="value" {...props} ref={ref} />,
)

/**
 * Text variant to use for text inputs.
 */
export const InputText = React.forwardRef<typeof Text, TextWithoutVariantProps>(
  (props, ref) => <Text variant="input" color="faded" {...props} ref={ref} />,
)

export interface BodyTextProps extends TextWithoutVariantProps {
  /**
   * @deprecated Use `fontWeight: 'bold'`
   */
  bold?: boolean
}

export const BodyText = React.forwardRef<typeof Text, BodyTextProps>(
  (
    {
      bold = false,
      // TODO When `bold` is removed, also remove the fontFamily and fontWeight
      // defaults as those are taken care of by `variant`.
      fontFamily = 'body',
      fontWeight = bold ? '600' : undefined,
      ...props
    },
    ref,
  ) => (
    <Text
      variant="body"
      fontFamily={fontFamily}
      fontWeight={fontWeight}
      {...props}
      ref={ref}
    />
  ),
)
