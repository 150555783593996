import React from 'react'
import {
  createTheme,
  ThemeProvider as RestyleThemeProvider,
} from '@shopify/restyle'

import spacing from './sizes'
import borderRadii from './radiis'
import borderWidth from './borderWidths'
import speeds from './speeds'
import shadows from './shadows'
import animation from './animation'
import colors from './palettes'
import backgroundColors from './backgrounds'
import foregroundColors from './foregrounds'
import breakpoints from './breakpoints'
import zIndices from './zIndices'
import { fontFamilies, fontWeights, textVariants } from './typography'

export const lightConfig = {
  colors,
  backgroundColors,
  foregroundColors,
  fontFamilies,
  fontWeights,
  textVariants,
  spacing,
  borderRadii,
  borderWidth,
  speeds,
  shadows,
  animation,
  zIndices,
  breakpoints,
  isDark: false,
}

export const light = createTheme(lightConfig)
export type LightTheme = typeof light

export interface LightThemeProviderProps {
  theme?: LightTheme
  children: React.ReactNode
}

// TODO Better name
export const LightThemeProvider = (props: LightThemeProviderProps) => (
  <RestyleThemeProvider theme={light} {...props} />
)
