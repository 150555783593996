import get from 'lodash/get'
import { AptTheme } from '../themes'
import { ResponsiveValue, useResponsiveProp } from './utils'
import { useTheme } from '../providers'

/**
 * The list of animation speeds available in the theme.
 */
export type ThemeSpeed = keyof AptTheme['speeds']
/**
 * The list of available theme animation speeds or a number
 */
export type Speed = number | ThemeSpeed
/**
 * A responsive animation speed configuration. Ex `{ phone: 's', tablet: 'm' }`
 */
export type ResponsiveThemeSpeed = ResponsiveValue<Speed>
/**
 * Get a speed value from the theme or fallback to the value passed. The speed
 * parameter can be a responsive config.
 */
export const getSpeed = (theme: AptTheme, speed: Speed) => {
  if (theme.speeds[speed]) return theme.speeds[speed] as number
  return speed as number
}

/**
 * Get a speed value from the theme or fallback to the value passed. The speed
 * parameter can be a responsive config.
 */
export const useSpeed = (speed: ResponsiveThemeSpeed) => {
  return getSpeed(useTheme(), useResponsiveProp<Speed>(speed))
}

type ThemeAnimationConfig = AptTheme['animation']
type ThemeEasingConfig = ThemeAnimationConfig['easing']
type ThemeEasingGroup = Exclude<keyof ThemeEasingConfig, 'linear'>
type ThemeEasingDirection = keyof ThemeEasingConfig[ThemeEasingGroup]
/**
 * The list of available easings in the theme.
 */
export type Easing = 'linear' | `${ThemeEasingGroup}.${ThemeEasingDirection}`
/**
 * A responsive easing configuration. Ex `{ phone: 'hard.out', tablet: 'slow.in' }`
 */
export type ResponsiveThemeEasing = ResponsiveValue<Easing>

/**
 * Get an easing value from the theme. The easing parameter can be a responsive
 * config.
 */
export const getEasing = (theme: AptTheme, easing: Easing) => {
  return get(theme.animation.easing, easing)
}

/**
 * Get an easing value from the theme. The easing parameter can be a responsive
 * config.
 */
export const useEasing = (easing: ResponsiveThemeEasing) => {
  const theme = useTheme()
  const key = useResponsiveProp<Easing>(easing)
  return getEasing(theme, key)
}

/**
 * The list of available spring animations.
 */
export type Spring = keyof ThemeAnimationConfig['spring']
/**
 * A responsive list of spring animations. Ex `{ phone: 'stiff', tablet: 'soft' }`
 */
export type ResponsiveThemeSpring = ResponsiveValue<Spring>

/**
 * Get a spring value from the theme. The spring parameter can be a responsive
 * config.
 */
export const useSpring = (spring: ResponsiveThemeSpring) => {
  const theme = useTheme()
  const key = useResponsiveProp<Spring>(spring)
  return theme.animation.spring[key]
}
