import React from 'react'
import { StyleSheet } from 'react-native'

import { Box, BoxProps, H1, BodyText } from '../../../core'
import { StyleTransition, TransitionConfig } from '../../../animation'
import { Error } from '~/assets'
import { env } from '~/env'
import { DarkThemeProvider } from '~/theme'

export interface IErrorView extends BoxProps {
  /**
   * The title text (or JSX element) to show as the error title.
   */
  title?: React.ReactNode
  /**
   * The text (or JSX element) to show as the main error body text.
   */
  message?: React.ReactNode
  /**
   * Whether or not you want to show the default help/contact text.
   */
  showHelp?: boolean
  /**
   * Whether to use the dark theme.
   */
  dark?: boolean
  /**
   * The length of the fade in animation. Set this to 0 during testing.
   */
  animationDuration?: number
}

const Title = ({
  dark,
  ...rest
}: React.ComponentProps<typeof H1> & { dark?: boolean }) => {
  const wrap = (c) => (dark ? <DarkThemeProvider children={c} /> : c)
  return wrap(<H1 textAlign="center" mb="s" {...rest} />)
}

const Text = ({
  dark,
  ...rest
}: React.ComponentProps<typeof BodyText> & { dark?: boolean }) => {
  const wrap = (c) => (dark ? <DarkThemeProvider children={c} /> : c)
  return wrap(<BodyText textAlign="center" {...rest} />)
}

/**
 * Use the `<ErrorView>` to provide a consistent error page across the
 * app in situations where the error should persist in the view (ex. 404 page,
 * user not authorized, server error, etc). For temporary error messages, you
 * should instead use the `Toast` component.
 *
 * You can customize the message by passing either the `message` prop or
 * `children`. Either of these props can include JSX content. You can
 * also specify a `title`.
 *
 * Additionally, there is a generic help text that can be displayed
 * by passing the `showHelp` prop. The advantage of passing this prop
 * is that it includes contact info for getting help.
 */
export const ErrorView = ({
  title,
  children,
  message = children,
  showHelp = true,
  dark,
  animationDuration,
  ...rest
}: IErrorView) => {
  const fadeIn = React.useMemo(
    () =>
      [
        {
          property: 'opacity',
          duration: animationDuration,
        },
      ] as TransitionConfig[],
    [animationDuration],
  )

  return (
    <Box testID="ErrorView" {...rest}>
      <StyleTransition
        p="l"
        style={styles.transition}
        state={true}
        transitions={fadeIn}
      >
        {title &&
          (typeof title === 'string' ? (
            <Title dark={dark} testID="errorTitle">
              {title}
            </Title>
          ) : (
            title
          ))}
        {(message || showHelp) && (
          <Box>
            {message &&
              (typeof message === 'string' ? (
                <Text dark={dark} testID="errorMessage">
                  {message}
                </Text>
              ) : (
                message
              ))}
            {showHelp && (
              <Text
                dark={dark}
                testID="defaultErrorMessage"
                marginTop={message ? 'xs' : undefined}
              >
                If you feel you reached this message in error, please contact{' '}
                {env.supportEmail}
              </Text>
            )}
          </Box>
        )}
        <Box style={styles.errorContainer} mt="l">
          <Error />
        </Box>
      </StyleTransition>
    </Box>
  )
}

const styles = StyleSheet.create({
  transition: {
    alignItems: 'center',
    maxWidth: 380,
  },
  errorContainer: {
    width: '100%',
    flex: 1,
    minHeight: 400,
  },
})
