import { AptTheme } from '../themes'
import {
  createRestyleFunction,
  createVariant as createRestyleVariant,
  type VariantProps,
} from '@shopify/restyle'
import { ResponsiveThemeProps } from './utils'
import { backgroundColorTransform } from './colors'

/**
 * A `shadow` prop that can be used to select one of the preset "shadow"
 * configurations from the `shadows` key in the theme.
 */
export const shadows = createRestyleVariant({
  property: 'boxShadow',
  themeKey: 'shadows',
})

/**
 * A responsive `shadow` prop tied to our theme.
 */
export type ShadowVariant = VariantProps<AptTheme, 'shadows', 'boxShadow'>

/**
 * A responsive `shadowColor` prop tied to the `backgroundColors` and `colors` keys in our theme.
 */
export const shadowColor = createRestyleFunction({
  property: 'shadowColor',
  themeKey: 'colors',
  transform: backgroundColorTransform,
})
/**
 * A responsive `shadowColor` prop tied to the `backgroundColors` and `colors` keys in our theme.
 */
export type ShadowColorProps = ResponsiveThemeProps<
  'shadowColor',
  keyof AptTheme['colors']
>
