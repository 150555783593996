import { createRestyleFunction } from '@shopify/restyle'
import { AptTheme } from '../themes'
import {
  ResponsiveThemeProps,
  ResponsiveValue,
  multiKeyTransformFactory,
  useResponsiveProp,
} from './utils'
import { useTheme } from '../providers'

const bgKeys = ['backgroundColor', 'bg'] as const

export const backgroundColorTransform = multiKeyTransformFactory([
  'backgroundColors',
  'colors',
])

/**
 * A helper to easily get one of the background colors from a theme object.
 *
 * ```ts
 * getBackgroundColor('primary', theme)
 * ```
 */
export const getBackgroundColor = (
  theme: AptTheme,
  color: BackgroundColorValues,
  /**
   * Silence warnings if the color doesn't exist in the theme.
   */
  silent?: boolean,
) => backgroundColorTransform({ value: color, theme }, silent)

/**
 * A restyle function that will return a color from either the `backgroundColors` or the
 * `colors` key in the theme.
 */
export const backgroundColors = bgKeys.map((key) =>
  createRestyleFunction({
    property: key,
    styleProperty: 'backgroundColor',
    themeKey: 'backgroundColors',
    transform: backgroundColorTransform,
  }),
)

/**
 * The list of properties that can be used to set background colors
 */
export type BackgroundColorKeys = TupleToUnion<typeof bgKeys>
/**
 * All values (non-responsive) that can be used with the `backgroundColor` prop.
 */
export type BackgroundColorValues =
  | keyof AptTheme['colors']
  | keyof AptTheme['backgroundColors']
/**
 * All possible responsive values that can be used as background colors.
 */
export type BackgroundColors = ResponsiveValue<BackgroundColorValues>
/**
 * A responsive `backgroundColor` prop tied to the `backgroundColors` and
 * `colors` keys in our theme.
 */
export type BackgroundColorProps = ResponsiveThemeProps<
  BackgroundColorKeys,
  BackgroundColorValues
>

/**
 * Get a background color from the theme. This can optionally be a responsive
 * object defining different colors per breakpoint.
 */
export const useBackgroundColor = (
  color: ResponsiveValue<BackgroundColorValues>,
  silent?: boolean,
) =>
  getBackgroundColor(
    useTheme(),
    useResponsiveProp<BackgroundColorValues>(color),
    silent,
  )

//
// FOREGROUND
//

export const foregroundColorTransform = multiKeyTransformFactory([
  'foregroundColors',
  'colors',
])
/**
 * A helper to easily get one of the background colors from a theme object.
 *
 * ```ts
 * getBackgroundColor('primary', theme)
 * ```
 */
export const getForegroundColor = (
  theme: AptTheme,
  color: ForegroundColorValues,
  /**
   * Silence warnings if the color doesn't exist in the theme.
   */
  silent?: boolean,
) => foregroundColorTransform({ value: color, theme }, silent)

/**
 * A restyle function that will return a color from either the `foregroundColors` or the
 * `colors` key in the theme.
 */
export const foregroundColors = createRestyleFunction({
  property: 'color',
  themeKey: 'foregroundColors',
  transform: foregroundColorTransform,
})

/**
 * All values (non-responsive) that can be used with the `foregroundColor` prop.
 */
export type ForegroundColorValues =
  | keyof AptTheme['colors']
  | keyof AptTheme['foregroundColors']
/**
 * All possible responsive values that can be used as foreground colors.
 */
export type ForegroundColors = ResponsiveValue<ForegroundColorValues>
/**
 * A responsive `foregroundColor` prop tied to the `foregroundColors` and
 * `colors` keys in our theme.
 */
export type ForegroundColorProps = ResponsiveThemeProps<
  'color',
  ForegroundColorValues
>

/**
 * Get a foreground color from the theme. This can optionally be a responsive
 * object defining different colors per breakpoint.
 */
export const useForegroundColor = (
  color: ResponsiveValue<ForegroundColorValues>,
  silent?: boolean,
) =>
  getForegroundColor(
    useTheme(),
    useResponsiveProp<ForegroundColorValues>(color),
    silent,
  )

//
// ANY COLOR
//

const allColorsTransform = multiKeyTransformFactory([
  'foregroundColors',
  'backgroundColors',
  'colors',
])
/**
 * All possible color values in our theme (foreground, background and palettes).
 */
export type AllColors =
  | keyof AptTheme['colors']
  | keyof AptTheme['foregroundColors']
  | keyof AptTheme['backgroundColors']
/**
 * A helper to easily get a color from any of the theme color properties.
 *
 * ```ts
 * getBackgroundColor('primary', theme)
 * ```
 */
export const getColor = (
  theme: AptTheme,
  color: AllColors,
  /**
   * Silence warnings if the color doesn't exist in the theme.
   */
  silent?: boolean,
) => allColorsTransform({ value: color, theme }, silent)

/**
 * Use any of the colors defined in the theme (foreground, background or palette).
 */
export const useColor = (color: ResponsiveValue<AllColors>, silent?: boolean) =>
  getColor(useTheme(), useResponsiveProp<AllColors>(color), silent)
