import gradient from './palettes/gradient'
import foreground from './palettes/foreground'
import background from './palettes/background'

export default {
  black: '#000000',
  white: '#FFFFFF',
  transparent: '#00000000',

  primary: gradient.primary[500].hex,
  'primary.50': gradient.primary[50].hex,
  'primary.100': gradient.primary[100].hex,
  'primary.200': gradient.primary[200].hex,
  'primary.300': gradient.primary[300].hex,
  'primary.400': gradient.primary[400].hex,
  'primary.500': gradient.primary[500].hex,
  'primary.600': gradient.primary[600].hex,
  'primary.700': gradient.primary[700].hex,
  'primary.800': gradient.primary[800].hex,
  'primary.900': gradient.primary[900].hex,
  'primary.A100': gradient.primary.A100.hex,
  'primary.A200': gradient.primary.A200.hex,
  'primary.A400': gradient.primary.A400.hex,
  'primary.A700': gradient.primary.A700.hex,

  secondary: gradient.secondary[500].hex,
  'secondary.50': gradient.secondary[50].hex,
  'secondary.100': gradient.secondary[100].hex,
  'secondary.200': gradient.secondary[200].hex,
  'secondary.300': gradient.secondary[300].hex,
  'secondary.400': gradient.secondary[400].hex,
  'secondary.500': gradient.secondary[500].hex,
  'secondary.600': gradient.secondary[600].hex,
  'secondary.700': gradient.secondary[700].hex,
  'secondary.800': gradient.secondary[800].hex,
  'secondary.900': gradient.secondary[900].hex,
  'secondary.A100': gradient.secondary.A100.hex,
  'secondary.A200': gradient.secondary.A200.hex,
  'secondary.A400': gradient.secondary.A400.hex,
  'secondary.A700': gradient.secondary.A700.hex,

  accent: gradient.accent[500].hex,
  'accent.50': gradient.accent[50].hex,
  'accent.100': gradient.accent[100].hex,
  'accent.200': gradient.accent[200].hex,
  'accent.300': gradient.accent[300].hex,
  'accent.400': gradient.accent[400].hex,
  'accent.500': gradient.accent[500].hex,
  'accent.600': gradient.accent[600].hex,
  'accent.700': gradient.accent[700].hex,
  'accent.800': gradient.accent[800].hex,
  'accent.900': gradient.accent[900].hex,
  'accent.A100': gradient.accent.A100.hex,
  'accent.A200': gradient.accent.A200.hex,
  'accent.A400': gradient.accent.A400.hex,
  'accent.A700': gradient.accent.A700.hex,

  grayscale: gradient.grayscale[500].hex,
  'grayscale.50': gradient.grayscale[50].hex,
  'grayscale.100': gradient.grayscale[100].hex,
  'grayscale.200': gradient.grayscale[200].hex,
  'grayscale.300': gradient.grayscale[300].hex,
  'grayscale.400': gradient.grayscale[400].hex,
  'grayscale.500': gradient.grayscale[500].hex,
  'grayscale.600': gradient.grayscale[600].hex,
  'grayscale.700': gradient.grayscale[700].hex,
  'grayscale.800': gradient.grayscale[800].hex,
  'grayscale.900': gradient.grayscale[900].hex,
  'grayscale.A100': gradient.grayscale.A100.hex,
  'grayscale.A200': gradient.grayscale.A200.hex,
  'grayscale.A400': gradient.grayscale.A400.hex,
  'grayscale.A700': gradient.grayscale.A700.hex,

  positive: gradient.positive[500].hex,
  'positive.50': gradient.positive[50].hex,
  'positive.100': gradient.positive[100].hex,
  'positive.200': gradient.positive[200].hex,
  'positive.300': gradient.positive[300].hex,
  'positive.400': gradient.positive[400].hex,
  'positive.500': gradient.positive[500].hex,
  'positive.600': gradient.positive[600].hex,
  'positive.700': gradient.positive[700].hex,
  'positive.800': gradient.positive[800].hex,
  'positive.900': gradient.positive[900].hex,
  'positive.A100': gradient.positive.A100.hex,
  'positive.A200': gradient.positive.A200.hex,
  'positive.A400': gradient.positive.A400.hex,
  'positive.A700': gradient.positive.A700.hex,

  negative: gradient.negative[500].hex,
  'negative.50': gradient.negative[50].hex,
  'negative.100': gradient.negative[100].hex,
  'negative.200': gradient.negative[200].hex,
  'negative.300': gradient.negative[300].hex,
  'negative.400': gradient.negative[400].hex,
  'negative.500': gradient.negative[500].hex,
  'negative.600': gradient.negative[600].hex,
  'negative.700': gradient.negative[700].hex,
  'negative.800': gradient.negative[800].hex,
  'negative.900': gradient.negative[900].hex,
  'negative.A100': gradient.negative.A100.hex,
  'negative.A200': gradient.negative.A200.hex,
  'negative.A400': gradient.negative.A400.hex,
  'negative.A700': gradient.negative.A700.hex,

  warn: gradient.warn[500].hex,
  'warn.50': gradient.warn[50].hex,
  'warn.100': gradient.warn[100].hex,
  'warn.200': gradient.warn[200].hex,
  'warn.300': gradient.warn[300].hex,
  'warn.400': gradient.warn[400].hex,
  'warn.500': gradient.warn[500].hex,
  'warn.600': gradient.warn[600].hex,
  'warn.700': gradient.warn[700].hex,
  'warn.800': gradient.warn[800].hex,
  'warn.900': gradient.warn[900].hex,
  'warn.A100': gradient.warn.A100.hex,
  'warn.A200': gradient.warn.A200.hex,
  'warn.A400': gradient.warn.A400.hex,
  'warn.A700': gradient.warn.A700.hex,

  foreground: foreground.default,
  background: background.default,
}
