import React from 'react'
import { AnimatedBox } from '../../core/box'
import { env } from '~/env'
import { AnimatedStyleTransition } from './AnimatedStyleTransition'
import { StyleTransitionProps, TransitionConfig } from './types'

/**
 * An implementation of StyleTransition that does not animate (useful for
 * testing)
 */
export const StaticStyleTransition = React.forwardRef(
  (
    {
      state = true,
      transitions,
      persistent = true,
      debug,
      style: styleProp,
      ...props
    }: StyleTransitionProps,
    ref,
  ) => {
    const value = state ? 1 : 0

    const getValue = (config: TransitionConfig) =>
      typeof config.value === 'function'
        ? (config.value(value) ?? value)
        : value

    const s = transitions.reduce((acc, curr) => {
      switch (curr.property) {
        case 'scale':
        case 'scaleX':
        case 'scaleY':
        case 'rotate':
        case 'rotateX':
        case 'rotateY':
        case 'rotateZ':
        case 'skewX':
        case 'skewY':
        case 'translate':
        case 'translateX':
        case 'translateY':
        case 'perspective':
        case 'matrix':
          if (!acc.transform) acc.transform = []
          acc.transform.push({
            [curr.property]: getValue(curr),
          })
          break
        // Non-transform related animations
        default:
          acc[curr.property] = getValue(curr)
          break
      }
      return acc
    }, {} as any)

    const style = [styleProp, s]

    if (debug) {
      // prettier-ignore
      // deno-fmt-ignore
      console.log(
        '[StaticStyleTransition]',
        '\nstate:', state,
        '\nprops:', props,
        '\nstyle:', style,
      )
    }

    React.useEffect(() => {
      transitions.forEach((t) => {
        if (t.onComplete) {
          t.onComplete(value, true)
        }
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    if (!persistent && !state) {
      return null
    } else {
      return <AnimatedBox style={style} {...props} ref={ref} />
    }
  },
)

/**
 * `<StyleTransition>` allows you to transition animatable style properties
 * between two states. It also allows animating the entrance/exit of a
 * component. `StyleTransition` extends `Box` so you can also pass any props of
 * `Box`.
 *
 * Use the `state` value to set the current state (`true` or `false`) of the animated props.
 * By default, the component will auto transition into the first state (ie. from
 * value 0 -> 1). However, you can disable entrance animations by passing
 * `animateEntrance={false}`.
 *
 * The `transitions` prop should be a list of configs that define which style
 * properties to transition. Something like the following:
 *
 * ```ts
 * {
 *   property: 'opacity',
 *   // or for `transform` props, use the transform property name like:
 *   // property: 'scale',
 *   duration: 'm', // a number or speed from our theme
 *   delay: 'xs',
 *   easing: 'hard.out', // an easing value from our theme
 *   // If you need to interpolate the value, you can pass a `value` function
 *   value: (value: number) => {
 *     // Be sure to mark this function as a 'worklet' so it can be called by
 *     // react-native-reanimated in the animation thread
 *     'worklet'
 *     return `${value * 360}deg`
 *   },
 *   // Optionally, you can pass a callback to be notificed when the animation
 *   // completes. The `finished` param will be false if the animation was
 *   // cancelled by starting another animation before the current animation
 *   // completed.
 *   onComplete: (finalValue: number, finished: boolean) => {
 *     console.log('Animation finished', finalValue, finished)
 *   }
 * }
 * ```
 *
 * Additionally, you can remove the component from the component tree when the
 * animation into the false state is complete by setting `persistent={false}`.
 *
 * The API allows specifying transform animations of varying durations which
 * will work on native. However, CSS transitions on web do not support varying
 * duration values for each transform so the duration of the last defined
 * transform will be used.
 *
 * In other words...
 *
 * ```ts
 * // Given the following config:
 * [
 *   { property: 'scale', duration: 1000 },
 *   { property: 'rotate', duration: 500 },
 * ]
 *
 * // The resulting CSS transition will be:
 * 'transition 500ms linear'
 * ```
 */
export const StyleTransition = React.forwardRef(
  ({ disableAnimations = env.test, ...rest }: StyleTransitionProps, ref) => {
    return disableAnimations ? (
      <StaticStyleTransition testID="StyleTransition" {...rest} ref={ref} />
    ) : (
      <AnimatedStyleTransition testID="StyleTransition" {...rest} ref={ref} />
    )
  },
)
