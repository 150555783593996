export default {
  // This should be the only color without transparency
  default: 'rgba(255, 255, 255, 1)',
  normal: 'rgba(255, 255, 255, 0.9)',
  light: 'rgba(255, 255, 255, 0.8)',
  faded: 'rgba(255, 255, 255, 0.4)',
  disabled: 'rgba(255, 255, 255, 0.3)',
  divider: 'rgba(255, 255, 255, 0.1)',
  border: 'rgba(255, 255, 255, 0.1)',
}
