export default {
  // Strong floating elements that need to appear far above other elements.
  overlay: {
    shadowColor: 'black',
    shadowOpacity: 0.7,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowRadius: 20,
    elevation: 20,
  },
  // Menus, dropdowns and select elements
  menu: {
    shadowColor: 'black',
    shadowOpacity: 0.2,
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowRadius: 10,
    elevation: 10,
  },
  // Lightly floating elements like the floating button.
  floating: {
    shadowColor: 'black',
    shadowOpacity: 0.08,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 14,
    elevation: 7,
  },
}
