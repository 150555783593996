import { createRestyleFunction } from '@shopify/restyle'
import { DimensionValue, FlexStyle } from 'react-native'
import { ResponsiveThemeProps, ResponsiveValue, themeOrValue } from './utils'
import { AptTheme } from '../themes'

type SpacingValues = keyof AptTheme['spacing']

const dimensionKeys = [
  'width',
  'minWidth',
  'maxWidth',
  'height',
  'minHeight',
  'maxHeight',
] as const

/**
 * An array of restyle functions that connect dimension properties (width,
 * height, etc) to the theme spacing variables but can also receive standard CSS
 * dimenions.
 */
export const dimensions = dimensionKeys.map((key) =>
  createRestyleFunction({
    property: key,
    themeKey: 'spacing',
    transform: themeOrValue('spacing'),
  }),
)

export type DimensionKeys = TupleToUnion<typeof dimensionKeys>
/**
 * The list of possible values that can be used as a dimension
 */
export type DimensionOrSpacingValue = DimensionValue | SpacingValues
/**
 * The list of responsive dimension props (width, height, etc) that can take
 * either a CSS dimension or a value tied to the spacing key in our theme.
 */
export type DimensionProps = ResponsiveThemeProps<
  DimensionKeys,
  DimensionOrSpacingValue
>

//
// LAYOUT
//

const layoutKeys = [
  'overflow',
  'aspectRatio',
  'alignContent',
  'alignItems',
  'alignSelf',
  'justifyContent',
  'flex',
  'flexBasis',
  'flexDirection',
  'flexGrow',
  'flexShrink',
  'flexWrap',
] as const

/**
 * An array of restyle functions for layout related props (flex, overflow,
 * aspectRation, etc). This is a subset of the `layout` functions provided by
 * Restyled without the width/height related properties. Those dimension
 * properties are customized to allow passing CSS values similar to
 * `styled-system`.
 */
export const layout = layoutKeys.map((key) =>
  createRestyleFunction({
    property: key,
  }),
)

export type LayoutKeys = TupleToUnion<typeof layoutKeys>
/**
 * The list of responsive layout related properties (flex, overflow, etc).
 */
export type LayoutProps = {
  [Key in LayoutKeys]?: ResponsiveValue<FlexStyle[Key]>
}

//
// POSITION
//

/**
 * A restyle function for responsive position (absolute/relative).
 */
export const position = createRestyleFunction({
  property: 'position',
})
/**
 * A responsive `position` prop
 */
export type PositionProps = ResponsiveThemeProps<
  'position',
  FlexStyle['position']
>

/**
 * A restyle function for responsive z-index values. Duplicates the version
 * provided by Restyle since we need to customize the position props.
 */
export const positionZ = createRestyleFunction({
  property: 'zIndex',
  themeKey: 'zIndices',
})

export type ZIndexProps = ResponsiveThemeProps<
  'zIndex',
  keyof AptTheme['zIndices']
>

const positionLayoutKeys = [
  'top',
  'right',
  'bottom',
  'left',
  'start',
  'end',
] as const

/**
 * A list of restyle functions that allow setting the position x/y location
 * using the spacing key from the theme but falling back to the value passed.
 */
export const positionLocation = positionLayoutKeys.map((key) =>
  createRestyleFunction({
    property: key,
    themeKey: 'spacing',
    transform: themeOrValue('spacing'),
  }),
)

export type PositionLocationKeys = TupleToUnion<typeof positionLayoutKeys>
/**
 * The list of responsive positioning layout properties (top, left, ect)
 * connected to the theme `spacing` or falling back to the value passed.
 */
export type PositionLocationProps = {
  [Key in PositionLocationKeys]?: ResponsiveValue<
    FlexStyle[Key] | SpacingValues
  >
}
