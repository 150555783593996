import React from 'react'
import { HeartbeatLoader, Box, BoxProps, Gradient } from '../../core'
import { HeartbeatAnimationState } from '../../animation'
import { DimensionValue } from 'react-native'

export interface SplashProps extends BoxProps {
  /**
   * The current playing state.
   *
   * - playing: The heartbeat animation will loop.
   * - paused: The heartbeat animation will pause where it is.
   * - stopped: The hearbeat animation will play to the end of the loop and then stop.
   */
  state?: HeartbeatAnimationState
  /**
   * The position of the logo from the top of the screen.
   * This will be used as the `top` prop on the `Box` component
   * that centers the logo.
   */
  logoPosition?: DimensionValue
  /**
   * The length of splash screen animations. Set this to 0
   * to turn off all animations during testing.
   */
  animationDuration?: number
}

/**
 * Use `<Splash>` to show the application splash screen.
 * You can use it inside of any other component and so it
 * does not need to be used full screen.
 */
export const Splash = ({
  state,
  logoPosition = '50%',
  animationDuration,
  ...rest
}: SplashProps) => {
  /* istanbul ignore next: Covered by animation hook tests. */
  state = state || 'playing'
  return (
    <Gradient
      testID="Splash"
      style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}
      {...rest}
    >
      <Box
        style={{
          position: 'absolute',
          top: logoPosition,
          width: '100%',
          alignItems: 'center',
        }}
      >
        <HeartbeatLoader
          color="white"
          showHouse
          state={state}
          duration={animationDuration}
          aria-label="Apartment Snapshot Logo Image"
        />
      </Box>
    </Gradient>
  )
}
