/**
 * Convert a CSS alpha value (0 - 1) into a hex string.
 */
export function alphaToHexAlpha(alpha: number) {
  return Number(255 * alpha)
    .toString(16)
    .split('.')[0]
    .substring(0, 2)
    .padStart(2, '0')
}

/**
 * Get a color from one of our color palettes at the specified alpha value.
 * Example Usage:
 *
 * ```js
 * import palettes from '~/theme/themes/light/palettes/gradients'
 * const color = paletteColorWithAlpha(palettes.grayscale, 0.3, 300);
 * ```
 */
export function addAlphaToHexColor(color: `#${string}`, alpha: number) {
  const a = alphaToHexAlpha(alpha)
  // TODO Guard against hex colors that already include an alpha
  return color + a
}
