import React from 'react'
import { Platform, ViewStyle } from 'react-native'
import { BoxProps, BoxRestyleProps } from '../../box'
import { TextProps } from '../../text'
import type { InnerActionProps } from './types'

export type ActionLook = 'button' | 'link'
export type ActionFeel = 'primary' | 'secondary'
export type IconPosition = 'start' | 'end'

export interface ActionStylingProps {
  /**
   * Remove the rounded corners from the button.
   */
  boxy?: boolean
  /**
   * Disable press events on the button/link.
   */
  disabled?: boolean
  /**
   * The visual style of the action ('primary', 'secondary', etc).
   */
  feel?: ActionFeel
  /**
   * Whether to use the floating style, which adds a box shadow behind the
   * action.
   */
  floating?: boolean
  /**
   * Whether to style this Action to look like a link or a button.
   * This only impacts the styling. To change the final rendered
   * output element, use the `href` or `link` props.
   */
  look?: ActionLook
  /**
   * An icon to place on one side of the `children`. Or you can use this in
   * place of children if you want.
   */
  icon?: React.ReactElement<any, string | React.JSXElementConstructor<any>>
  /**
   * The side you want to place the icon. This defaults to "start" which places
   * the icon on the left of the content (or above if `flexDirection="column"`).
   */
  iconPosition?: IconPosition
  /**
   * Remove all styling in order to provide fully custom action styling.
   */
  transparent?: boolean
}

export const DEFAULT_BORDER_RADIUS = 's'
export const DEFAULT_PADDING = 's'

/**
 * A function that returns theme and style props that will style a component as
 * one of our button styles. The function returns a object with `style` and
 * `themeProps`.
 *
 * - `style` = styles to apply directly to the `style` prop of your component
 * - `themeProps` = props to spread on your component. These are Restyle based
 *   props that will use the theme.
 *
 * @example
 * ```tsx
 * function MyComponent({style: styleProp, ...props}) {
 *   const { themeProps, style } = buttonStyle({look: 'button', feel: 'primary'})
 *
 *   return (
 *     <Something
 *       // Spread the theme based props on your component
 *       {...themeProps}
 *       // Place your style overrides after the theme props
 *       padding="l"
 *       style={[
 *         // Add the button styles first
 *         style,
 *         // Add any styles you want to override 2nd
 *         myComponentStyles,
 *         // Pass external styles last so they override everything else
 *         styleProp
 *       ]}
 *       // Pass external props last so they override everything else
 *       {...props}
 *     />
 *   )
 * }
 * ```
 */
export const buttonStyles = ({
  disabled,
  look,
  feel,
  transparent,
  floating,
  boxy,
  icon,
  hasChildren,
  iconPosition,
}: ActionStylingProps & InnerActionProps) => {
  const themeProps: BoxRestyleProps = {
    flexDirection: 'row',
    overflow: 'hidden',
  }
  // Only properties that don't have a responsive equivalent should be added to
  // this "style" object or the style cascading will not work correctly
  // (responsive props set on the parent will not override style props).
  let style: ViewStyle = {
    display: 'flex',
  }

  if (disabled) {
    themeProps.opacity = 0.5
    style = {
      ...style,
      ...Platform.select({
        web: {
          pointerEvents: 'none',
          // cursor: 'default',
        },
      }),
    }
  }

  if (!transparent) {
    themeProps.alignItems = 'center'

    if (icon) {
      themeProps.justifyContent = 'space-between'
    }

    if (look === 'button') {
      if (!icon) {
        // Center the text in the button by default
        themeProps.justifyContent = 'center'
      }

      if (!boxy) {
        themeProps.borderRadius = DEFAULT_BORDER_RADIUS
      }

      const large = DEFAULT_PADDING
      const small = icon ? 'xs' : DEFAULT_PADDING
      const left =
        icon && hasChildren && iconPosition === 'start'
          ? small
          : hasChildren
            ? large
            : small
      const right =
        icon && hasChildren && iconPosition === 'end'
          ? small
          : hasChildren
            ? large
            : small
      const vertical = small
      themeProps.paddingTop = vertical
      themeProps.paddingBottom = vertical
      themeProps.paddingLeft = left
      themeProps.paddingRight = right

      if (feel !== 'primary') {
        themeProps.backgroundColor = 'default'
      }

      if (floating) {
        // Allows the shadow to be seen
        themeProps.overflow = 'visible'
        themeProps.boxShadow = 'floating'
      }
    }
  }

  return { themeProps, style }
}

/**
 * A function to apply the base link props. This will apply our button styling
 * and add generic properties like `role` and `accessibilityState`. Any
 * additional props you pass will be passed through so you can apply the
 * returned object directly to your component. This includes correctly applying
 * styles and allowing prop overrides.
 *
 * @example
 * ```tsx
 * function MyComponent(props) {
 *   return (
 *     <Something
 *       // Spread the returned value directly on your component. All props
 *       // that can be added to a `Text` or `View` will be passed through.
 *       {...genericLinkProps(props)}
 *     />
 *   )
 * }
 * ```
 */
export const genericLinkProps = ({
  style: styleProp,
  // These properties should not be passed along
  look = 'link',
  role = 'link',
  accessibilityRole = 'link',
  feel,
  transparent,
  floating,
  boxy,
  icon,
  iconPosition,
  hasChildren,
  ...props
}: TextProps & ActionStylingProps & InnerActionProps) => {
  const { themeProps, style } = buttonStyles({
    disabled: props.disabled,
    look,
    feel,
    transparent,
    floating,
    boxy,
    icon,
    iconPosition,
    hasChildren,
  })

  return {
    testID: 'Link',
    role: role,
    // accessibilityRole is deprecated but we can't remove it's usage until we
    // upgrade to the latest @testing-library/react-native which requires some
    // migration steps.
    accessibilityRole: accessibilityRole,
    accessibilityState: {
      disabled: props.disabled != null ? !!props.disabled : undefined,
    },
    style: [style, styleProp].filter((v) => !!v),
    ...themeProps,
    ...props,
  }
}

/**
 * A function to apply the base button props. This will apply our button styling
 * and add generic properties like `role` and `accessibilityState`. Any
 * additional props you pass will be passed through so you can apply the
 * returned object directly to your component. This includes correctly applying
 * styles and allowing prop overrides.
 *
 * @example
 * ```tsx
 * function MyComponent(props) {
 *   return (
 *     <Something
 *       // Spread the returned value directly on your component. All props
 *       // that can be added to a `Text` or `View` will be passed through.
 *       {...genericButtonProps(props)}
 *     />
 *   )
 * }
 * ```
 */
export const genericButtonProps = ({
  testID = 'Button',
  look = 'button',
  role = 'button',
  accessibilityRole = 'button',
  ...props
}: BoxProps & ActionStylingProps & InnerActionProps) => {
  return genericLinkProps({
    testID,
    look,
    role,
    accessibilityRole,
    ...props,
  })
}
