import AsyncStorage from '@react-native-async-storage/async-storage'
import {
  refreshAsync,
  exchangeCodeAsync,
  revokeAsync,
  AuthRequest,
  makeRedirectUri,
  generateHexStringAsync,
  ResponseType,
  type TokenResponse,
} from 'expo-auth-session'
import { atob } from 'react-native-quick-base64'
import { jwtDecode } from 'jwt-decode'
import omit from 'lodash/omit'
import { Platform } from 'react-native'
import { ServiceBase } from '@thesoulfresh/utils'

import { env } from '~/env'
import { AuthService, AuthResponse } from './AuthService'
import { DISCOVERY } from './discovery'
import { fromAuth0 } from './fromAuth0'
import { makeLogger } from '~/utils'

// Polyfill for atob (needed by `jwt-decode`)
if (!global.atob) global.atob = atob

export const REFRESH_TOKEN_KEY = 'rtk'

const AuthSession = {
  refreshAsync,
  exchangeCodeAsync,
  revokeAsync,
  makeRedirectUri,
  generateHexStringAsync,
  AuthRequest,
}
export type AuthClient = typeof AuthSession

/**
 * This is the interface we use from
 * `import AsyncStorage from '@react-native-async-storage/async-storage'`
 */
export interface Storage {
  setItem: (key: string, value: string) => Promise<any>
  getItem: (key: string) => Promise<string | null>
  removeItem: (key: string) => Promise<any>
}

/**
 * The `Auth0` service uses OAuth to authenticate a user against
 * auth0.com. When the user logs in, they will be taken to the
 * Auth0 website in a popup window where they can log in. The
 * popup window will then pass an access token (sort of) to the application
 * that can be used to make API requests.
 *
 * ## Popup Windows
 *
 * Because this service tries to open a popup window, the UI needs
 * to handle cases where the popup window is blocked or the window
 * gets hidden behind other windows on the user's screen (this is
 * only an issue on web). The UI should present button's for re-opening
 * the popup/re-focusing it by re-calling `login`.
 *
 * ## Developer Notes
 *
 * This service uses an OAuth flow called Authorization Code Flow with PKCE
 * (as opposed to the Implicit Grant Flow)
 * https://auth0.com/docs/authorization/flows/authorization-code-flow-with-proof-key-for-code-exchange-pkce
 *
 * This means we will send the user to an Auth0 page to
 * authenticate and then will get back a code that can be
 * exchanged for an access token and refresh token. The
 * access token can be used to make API requests and the
 * refresh token can be used to refresh the access token
 * when it expires.
 *
 * When using the PKCE auth flow in an SPA or native app,
 * it's important to configure refresh token rotation in Auth0
 * in order to help prevent unauthorized use of the refresh tokens.
 * https://auth0.com/blog/refresh-tokens-what-are-they-and-when-to-use-them/#Refresh-Token-Rotation
 *
 * Our token expirations at time of writing:
 * - id_token: 36,000s (10hrs)
 *   The token used to show that the user is authenticated
 * - refresh_token:
 *   The token used to get a new id_token after it expires
 *   - reuse interval: 0
 *     Require a new refresh token every time we request the id token.
 *   - inactivity lifetime: 1,296,000s (15 days)
 *     How long the refresh token lasts without being used.
 *   - absolute lifetime: 2,592,000s (30 days)
 *     How long we can exchange refresh tokens for id tokens without
 *     prompting the user to log in.
 *
 * Additionally, part of this flow takes the user to a login page
 * hosted by Auth0. That page includes a session cookie which is used
 * to keep the user logged if the user sees the login page within the
 * inactivity window configured in the Auth0 dashboard. However, because
 * we use refresh token rotation, we do not need this functionality.
 * As a result the Auth0 session should be configured to a very short
 * inactivity timeout (1 minute). This ensures that if the user uses
 * our log out button, they will be forced to re-enter their credentials
 * when they next login. See the comments in the `logout()` function
 * for more details.
 * https://auth0.com/docs/manage-users/sessions/configure-session-lifetime-settings
 *
 * ## Further Reading:
 *
 # The following provides a great explanation about the various auth tokens
 # at play here and how they are used:
 # https://auth0.com/blog/refresh-tokens-what-are-they-and-when-to-use-them/
 #
 # The following provide an overview of best practices for configuring
 # refresh token expiration times and how to do that through the Auth0 Dashboard:
 # - https://auth0.com/blog/achieving-a-seamless-user-experience-with-refresh-token-inactivity-lifetimes/
 # - https://auth0.com/docs/security/tokens/refresh-tokens/configure-refresh-token-expiration
 #
 # Auth0 API Docs:
 # https://auth0.com/docs/api/authentication?http#authorization-code-flow-with-pkce46
 #
 # Generate Token Best Practices:
 # https://auth0.com/docs/best-practices/token-best-practices
 *
 * Expo Auth Docs (not the most descriptive or helpful)
 * https://docs.expo.dev/versions/latest/sdk/auth-session
 *
 * @fires Auth0#login
 * @fires Auth0#logout
 */
export class Auth0 extends ServiceBase<AuthClient> implements AuthService {
  storage: Storage
  authDomain: string
  authClientId: string
  discovery: Record<string, unknown>
  log: Console

  /**
   * The following is just an example of how you might construct
   * your auth client.
   */
  constructor(
    /* istanbul ignore next: This should always be specified in tests */
    /**
     * Our client id in Auth0.
     */
    authClientId: string = env.authClientId,
    /* istanbul ignore next: This should always be specified in tests */
    /**
     * Our personal Auth0 domain for auth requests.
     */
    authDomain: string = env.authDomain,
    /* istanbul ignore next: This should always be specified in tests */
    /**
     * Whether to perform verbose logging.
     */
    verbose: boolean = env.verbose,
    /* istanbul ignore next: This should always be specified in tests */
    /**
     * The Expo client interface. Use this to override
     * the Expo dependencies during testing.
     */
    client: AuthClient = AuthSession,
    /* istanbul ignore next: This should always be specified in tests */
    /**
     * Allows you to configure the storage client
     * used for persisting refresh tokens.
     */
    storage: Storage = AsyncStorage,
  ) {
    super(client, verbose)

    const replace = (def: string) => def.replace('<DOMAIN>', authDomain)

    this.log = makeLogger('Auth0', '#37cda4', verbose)
    this.storage = storage
    this.authDomain = authDomain
    this.authClientId = authClientId
    this.discovery = {
      logoutURL: replace(DISCOVERY.logout_url),
      authorizationEndpoint: replace(DISCOVERY.authorization_endpoint),
      revocationEndpoint: replace(DISCOVERY.revocation_endpoint),
      tokenEndpoint: replace(DISCOVERY.token_endpoint),
      deviceAuthorizationEndpoint: replace(
        DISCOVERY.device_authorization_endpoint,
      ),
      userinfoEndpoint: replace(DISCOVERY.userinfo_endpoint),
      mfaChallengeEndpoint: replace(DISCOVERY.mfa_challenge_endpoint),
      registrationEndpoint: replace(DISCOVERY.registration_endpoint),
    }
  }

  /**
   * Try to log the user in with an existing refresh token.
   * This can also be used to refresh the existing access
   * token.
   */
  async authenticate(): Promise<AuthResponse> {
    this.log.debug('checking user authentication')

    const refreshToken = await this.getRefreshToken()

    if (refreshToken) {
      return this.client
        .refreshAsync(
          {
            refreshToken,
            clientId: this.authClientId,
          },
          this.discovery,
        )
        .then((response) => {
          return this.onLogin(response)
        })
    } else {
      this.log.debug('user is not currently authenticated')
      return Promise.reject(false)
    }
  }

  /**
   * Log the user into the app by opening a browser window
   * to Auth0.
   */
  async login(): Promise<AuthResponse> {
    this.log.debug('starting user login')
    // Create a request object for communicating with our auth server.
    const request = await this.configureAuthRequest()

    // Open the popup window that allows the user to authenticate.
    // Here is a link to the actual network request we will
    // be making:
    // https://auth0.com/docs/api/authentication?http#authorization-code-flow-with-pkce46
    const response = await request.promptAsync(this.discovery)

    if (response.type !== 'success') {
      this.log.error('Authentication failed', response)
      // @ts-ignore: This should work but not sure what's going on.
      throw new Error(`Auth Failure: ${response.error || response.type}`)
    }

    // Now that we have the challenge code, we can exchange it
    // for an access token that can be used to access remote resources.
    // Here's the network request we will be making:
    // https://auth0.com/docs/api/authentication?http#refresh-token
    const tokenResponse = await this.client.exchangeCodeAsync(
      {
        clientId: this.authClientId,
        code: response.params.code,
        redirectUri: request.redirectUri,
        extraParams: {
          code_verifier: request.codeVerifier,
        },
      },
      this.discovery,
    )

    return this.onLogin(tokenResponse, request.extraParams.nonce)
  }

  /**
   * @private
   * Handle a refresh token exchange.
   */
  onLogin(response: TokenResponse, nonce?: string): AuthResponse {
    this.log.debug('received Auth0 auth token; verfying token...')
    // Verify the token is valid and generate the data to return to the app.
    const { token, user: authUser } = this.verifyTokenResponse(response, nonce)

    const user = fromAuth0.user(authUser)

    // Save the refresh token so we can get a new access token later.
    // If the token fails to save, proceed anyway because saving the token is
    // just an optimization.
    this.storeRefreshToken(response.refreshToken).catch((e) => {
      this.log.error('[Auth0] Unable to store refresh token.', e)
    })

    const out = { token, user }

    /**
     * @event Auth0#login
     */
    this.emit('login', out)

    // Make sure not to log tokens to the console!
    this.log.info('Login Success:', out.user)
    return out
  }

  /**
   * Log the user out of the application.
   */
  async logout(): Promise<boolean> {
    this.log.debug('starting user logout process')
    const token = await this.getRefreshToken()

    // Remove the refresh token stored in local storage
    // so we don't try to use it on app/page refresh.
    try {
      // Clear the stored refresh token.
      await this.clearRefreshToken()
    } catch (e) {
      this.log.warn('Unable to clear the refresh token from local storage.', e)
    }

    // Revoke the user's refresh token so it can't be used
    // if it gets compromised. Even through our refresh tokens
    // rotate, the current token in storage is still valid and
    // could be used to request a new refresh token and the current
    // access/id tokens.
    if (token) {
      try {
        // Revoke the current refresh token so it can't be used.
        await this.client.revokeAsync(
          {
            token,
            clientId: this.authClientId,
          },
          this.discovery,
        )
      } catch (e) {
        this.log.warn('An error occurred revoking the current access token:', e)
      }
    }

    // Retaining this code should we choose to bring it back in the future.
    // For the moment, I have set a very short timeout for the Auth Browser
    // session cookie in the Auth0 dashboard inorder to force users to
    // re-enter their credentials after 1 minute (but only if they don't
    // have a refresh token)...
    //
    // Clear the Auth0 session cookies stored in the Auth Browser.
    // Unfortunately, this approach shows a popup asking users to log IN
    // even though they are logging OUT. This popup message is hardcoded
    // into the iOS platform and cannot be configured or changed.
    // So while the following code will correctly clear the Auth0
    // session cookie, the user experience on iOS will be confusing.
    // For other platforms, users will see a standard logout flow (ie. good UX).
    // try {
    //   const redirectURL = Linking.createURL("/")
    //   const logoutURL = this.discovery.logoutURL + `?client_id=${this.authClientId}&returnTo=${redirectURL}`
    //   await WebBrowser.openAuthSessionAsync(
    //     logoutURL,
    //     redirectURL, // Docs say this is optional but the types require it
    //   );
    // } catch (error) {
    //   this.log.error(error);
    // }

    /**
     * @event Auth0#logout
     */
    this.emit('logout')

    this.log.info('Logged Out')
    return true
  }

  /**
   * @private
   * Verify the response from a token request. This will
   * check the nonce and auth client id on the token
   * are correct and then return the access token and
   * user profile information.
   * @param response - The response from a token request.
   *   It should have accessToken and idToken properties.
   * @param nonce - The nonce passed with the initial request.
   * @return The access token and user profile information.
   */
  verifyTokenResponse(
    response: TokenResponse,
    nonce?: string,
  ): { token: string; user: any } {
    const decoded: any = jwtDecode(response.idToken)
    this.log.debug('decoded token:', decoded)

    // Verify that the token wasn't tampered with.
    if (!decoded.aud || decoded.aud !== this.authClientId) {
      throw new Error('Invalid OAuth id_token')
    }
    // Verify the nonce matches. It's important to allow the response not to include
    // a nonce because Auth0 does not return the nonce on refresh token requests.
    if (decoded.nonce !== nonce) {
      throw new Error('Invalid OAuth id_token')
    }

    return {
      // This is the token we will use to make API requests.
      token: response.idToken,
      // Remove anything relatively sensative from the token data
      // we will pass around the application.
      // @ts-ignore: Not sure how to coerce this correctly
      user: omit(decoded, ['aud', 'nonce', 'sub', 'exp', 'iat', 'iss']),
    }
  }

  /**
   * @private
   * Create an `AuthRequest` object that is configured
   * for use in any of the specific auth methods like `login`.
   * @param options - Any additional options to add to the default
   *   request configuration.
   */
  async configureAuthRequest(options: any = {}): Promise<AuthRequest> {
    // We will verify the nonce remains unchanged when
    // we get the id token back from Auth0.
    const nonce = await this.makeNonce()

    let redirectURL = this.client.makeRedirectUri({})
    if (Platform.OS !== 'web') {
      // Auth0 requires a triple slash in native app URLs.
      redirectURL += '/'
    }
    this.log.info('Redirect URL:', redirectURL)

    const request = new this.client.AuthRequest({
      redirectUri: redirectURL,
      clientId: this.authClientId,
      // Authenticate the user and get data about the user.
      // responseType: 'token id_token',
      responseType: ResponseType.Code,
      scopes: [
        // Use OICD to verify the user's identity
        'openid',
        // Request a refresh token in order to keep the auth session alive.
        // Must enable refresh tokens in Auth0 console to obtain a refresh token
        'offline_access',
        // Get the user's profile information (name, picture, etc)
        'profile',
        'email',
      ],
      // Use PKCE to allow secure refresh tokens in an SPA:
      // https://auth0.com/docs/security/tokens/refresh-tokens/refresh-token-rotation
      usePKCE: false,
      ...options,
      extraParams: {
        nonce,
        ...options?.extraParams,
      },
    })

    // Configure the request with the auth URL.
    const url = await request.makeAuthUrlAsync(this.discovery)
    this.log.info('Authenticating to:', url)

    return request
  }

  /**
   * @private
   * Save the given refresh token between sessions.
   * @param token - The refresh token value that can be used to
   *   get the next access token.
   */
  storeRefreshToken(token: string): Promise<boolean> {
    if (this.storage) {
      // Save the token to local storage. Is that safe?
      // Yes: https://auth0.com/blog/refresh-tokens-what-are-they-and-when-to-use-them/#You-Can-Store-Refresh-Token-In-Local-Storage
      return this.storage.setItem(REFRESH_TOKEN_KEY, token)
    } else {
      /* istanbul ignore next: If storage is not configured our tests will fail */
      return Promise.resolve(true)
    }
  }

  /**
   * @private
   * Get the last known refresh token.
   * There is no guarantee that this token is fresh.
   */
  getRefreshToken(): Promise<string | null> {
    if (this.storage) {
      return this.storage.getItem(REFRESH_TOKEN_KEY)
    } else {
      /* istanbul ignore next: If storage is not configured our tests will fail */
      return Promise.reject(false)
    }
  }

  /**
   * @private
   * Clear the stored refresh token.
   */
  clearRefreshToken(): Promise<boolean> {
    if (this.storage) {
      return this.storage.removeItem(REFRESH_TOKEN_KEY)
    } else {
      /* istanbul ignore next: If storage is not configured our tests will fail */
      return Promise.resolve(true)
    }
  }

  /**
   * Generate a unique nonce to pass with requests.
   */
  async makeNonce() {
    this.log.log('generating nonce...')
    // The nonce is used to help prevent replay attacks.
    // It should be unique for every request and never reused.
    return await this.client.generateHexStringAsync(16)
  }
}
