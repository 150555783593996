import background from './palettes/background'

export default {
  default: background.default,
  shaded: background.shaded,
  deeplyShaded: background.deeplyShaded,
  overlay: background.overlay,
  fade: background.fade,
  lightlyFade: background.lightlyFade,
  input: background.input,
  hover: background.hover,
  selected: background.selected,
}
