// NOTE: This formatting specifically used to ensure readability of event names
// and categories.
// prettier-ignore
export const AnalyticsEvents = {
  login_start:           { name:'Login Start'             , category:'Authentication'     , build:login_start           },
  login_success:         { name:'Login Success'           , category:'Authentication'     , build:login_success         },
  login_failure:         { name:'Login Failure'           , category:'Authentication'     , build:login_failure         },
  logout_start:          { name:'Logout Start'            , category:'Authentication'     , build:logout_start          },
  logout_success:        { name:'Logout Success'          , category:'Authentication'     , build:logout_success        },
  logout_failure:        { name:'Logout Failure'          , category:'Authentication'     , build:logout_failure        },
  session_expired:       { name:'Session Expired'         , category:'Authentication'     , build:session_expired       },

  application_open:      { name:'Application Opened'      , category:'Application'        , build:application_open      },
  application_background:{ name:'Application Backgrounded', category:'Application'        , build:application_background},
  application_error:     { name:'Application Error'       , category:'Error'              , build:application_error     },
  last_line_of_defence:  { name:'Last Line Of Defence'    , category:'Error'              , build:last_line_of_defence  },
  main_error_page:       { name:'Main Error Page'         , category:'Error'              , build:main_error_page       },

  splash_duration:       { name:'Splash Screen Duration'  , category:'Application State'  , build:splash_duration       },

  permission_prompt:     { name:'Permission Prompt'       , category:'Permissions'        , build:permission_prompt     },
  location_change:       { name:'Location Change'         , category:'Settings'           , build:location_change       },
  notification_change:   { name:'Notification Change'     , category:'Settings'           , build:notification_change   },
  notification_received: { name:'Notification Received'   , category:'Notification'       , build:notifcation_received  },

  user_menu_open:        { name:'User Menu Open'          , category:'User Menu'          , build:user_menu_open        },
  user_menu_select:      { name:'User Menu Select'        , category:'User Menu'          , build:user_menu_select      },

  table_sort:            { name:'Table Sort'              , category:'Table'              , build:table_sort            },
  table_filter:          { name:'Table Filter'            , category:'Table'              , build:table_filter          },
  table_show_column:     { name:'Table Show Column'       , category:'Table'              , build:table_show_column     },

  property_select_item:  { name:'Property Select Item'    , category:'Select'             , build:property_select_item  },
  metric_select_item:    { name:'Metric Select Item'      , category:'Select'             , build:metric_select_item    },

  data_finalized_open:   { name:'Data Finalized Open'     , category:'Tooltip'            , build:data_finalized_open   },
  metric_info_open:      { name:'Metric Info Open'        , category:'Tooltip'            , build:metric_info_open      },

  property_staff_open:   { name:'Property Staff Open'     , category:'Collapsible Section', build:property_staff_open   },

  month_previous:        { name:'Month Previous'          , category:'Date Change'        , build:month_previous        },
  month_next:            { name:'Month Next'              , category:'Date Change'        , build:month_next            },

  support_form_submit:   { name:'Support Form Submit'     , category:'Form'               , build:support_form_submit   },
  property_entered:      { name:'Property Entered'        , category:'Location'           , build:property_entered      },
} as const

type IAnalyticsEvents = typeof AnalyticsEvents
export type AnalyticsEventNames =
  IAnalyticsEvents[keyof IAnalyticsEvents]['name']
export type AnalyticsEventOptions = ReturnType<
  IAnalyticsEvents[keyof IAnalyticsEvents]['build']
>

function login_start() {
  return {
    category: this.category,
    description:
      'When a user starts the login process by pressing the Login button.',
  }
}

function login_success() {
  return {
    category: this.category,
    description:
      'When a user has sucessfully logged in and returned from the login window to the first page of the app.',
  }
}

function login_failure() {
  return {
    category: this.category,
    description:
      'When a failure occurs in the login window (ex. wrong credentials, login configuration error, network error).',
  }
}

function logout_start() {
  return {
    category: this.category,
    description:
      'When a user starts the logout process by pressing the Logout button.',
  }
}

function logout_success() {
  return {
    category: this.category,
    description:
      'When a user has successfully logged out and is taken to the login page.',
  }
}

function logout_failure() {
  return {
    category: this.category,
    description:
      "When a failure occurs revoking the user's authentication token (ex. network error, auth0 configuration error)",
  }
}

function session_expired() {
  return {
    category: this.category,
    description:
      'When a user makes a network request but their authentication token is expired and they are taken to the login screen.',
  }
}

function application_open(fromBackground: boolean) {
  return {
    from_background: fromBackground,
    category: this.category,
    description:
      'When the app is opened, either by loading it or by reopening it from the background.',
  }
}

function application_background() {
  return {
    category: this.category,
    description:
      'When the app is closed or a different app/window receives focus.',
  }
}

/**
 * @param message The Error object's message
 */
function application_error(message: string) {
  return {
    message,
    stack: new Error().stack,
    category: this.category,
    description:
      'When an error (ex. network error) occurs in the application. This is different than when the Error page is shown which is tracked as a PageView event.',
  }
}

/**
 * @param message The Error object's message
 */
function last_line_of_defence(message: string) {
  return {
    message,
    stack: new Error().stack,
    category: this.category,
    description:
      'When the root error page is shown. This means than an unhandled error occured during startup or login.',
  }
}

/**
 * @param message The Error object's message
 */
function main_error_page(message: string) {
  return {
    message,
    stack: new Error().stack,
    category: this.category,
    description:
      'When the main error page is shown. This means than an unhandled error occured after startup/login.',
  }
}

/**
 * @param duraton The duration that the splash screen was shown in
 *   milliseconds.
 * @param authenticated Whether the user was already authenticated or if they
 *   were presented with the login page.
 */
function splash_duration(duration: number, authenticated: boolean) {
  return {
    authenticated,
    duration: duration,
    category: this.category,
    description:
      'The number of milliseconds that the startup screen was shown before either the login page or an inner page was shown.',
  }
}

function permission_prompt(permission: string, choice: 'enable' | 'skip') {
  return {
    permission,
    choice,
    category: this.category,
    description:
      'The choice made by the user when presented with a Permissions prompt on app first run.',
  }
}

/**
 * @param state The state that the location was changed to.
 */
function location_change(state: 'on' | 'off') {
  return {
    state,
    category: this.category,
    description: 'The user enabled or disenabled location tracking.',
  }
}
/**
 * @param item The notification that was changed or "all" if globally
 * enabling/disabling notifications.
 */
function notification_change(item = 'all', state: 'on' | 'off') {
  return {
    item,
    state,
    category: this.category,
    description: 'The user enabled or disabled one or all notifications.',
  }
}
/**
 * @param type A description of the notification received.
 */
function notifcation_received(
  type: 'url' | 'unknown',
  title: string,
  subtitle: string,
) {
  return {
    type,
    title,
    subtitle,
    category: this.category,
    description: 'A notification was received by a user.',
  }
}
function user_menu_open() {
  return {
    category: this.category,
    description: 'The user opened the user menu.',
  }
}
/**
 * @param item The display name of the item selected
 */
function user_menu_select(item: string) {
  return {
    item,
    category: this.category,
    description: 'An item in the user menu was selected by the user.',
  }
}
/**
 * @param column The title of the column sorted
 * @param direction The sort direction
 */
function table_sort(column: string, direction: number) {
  return {
    column,
    direction: direction === 1 ? 'asc' : 'desc',
    category: this.category,
    description: 'The user sorted a table column.',
  }
}
/**
 * @param id A label to identify this filter on the current page. Need only
 *   be unique to the page.
 */
function table_filter(id: string) {
  return {
    id,
    category: this.category,
    description: 'The user filtered the data in a table.',
  }
}
/**
 * @param column The title of the column being shown
 */
function table_show_column(column: string) {
  return {
    column,
    category: this.category,
    description: 'The user showed a hidden table column.',
  }
}
/**
 * @param property The name of the property selected
 */
function property_select_item(property: string) {
  return {
    property,
    category: this.category,
    descrption:
      'The user changed the current property using the property select in the application header.',
  }
}
/**
 * @param metric The name of the metric selected
 */
function metric_select_item(metric: string) {
  return {
    metric,
    category: this.category,
    description:
      'The user changed the current metric being viewed using the metric select menu.',
  }
}
function data_finalized_open() {
  return {
    category: this.category,
    description: 'The user showed the "Data Finalized" tooltip.',
  }
}
function metric_info_open() {
  return {
    category: this.category,
    description: 'The user showed the metric information tooltip.',
  }
}
function property_staff_open() {
  return {
    category: this.category,
    description: 'The user showed the property staff list.',
  }
}
function month_previous() {
  return {
    category: this.category,
    description: "The user switched to the previous month's data.",
  }
}
function month_next() {
  return {
    category: this.category,
    description: "The user switched to the previous month's data.",
  }
}
/**
 * @param type The type of support needed (exception, question, etc.)
 */
function support_form_submit(type: string) {
  return {
    type,
    category: this.category,
    description: 'The user submitted the support form.',
  }
}
/**
 * For privacy reasons, this event should NOT track the property entered or
 * any location data.
 */
function property_entered() {
  return {
    category: this.category,
    description: 'The user entered the geolocation for a property.',
  }
}
