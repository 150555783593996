import { state } from './state'
import { filterLogEvent, getConsoleProperty, makePrefixedLogger } from './utils'

/**
 * Give the provided object all of the window.console methods but with the
 * ability to enable/disable, filter and prefix logging calls.
 *
 * This version of `loggerMixin` uses Proxy to allow updating global logging
 * state at runtime.
 */
export function makeProxyLogger(
  /**
   * The item to receive logging functionality.
   */
  item: object,
  /**
   * A name to prefix before all logs genterated by the new logger.
   */
  name?: string,
  /**
   * Allows enabling this logger instance even if global logging is disabled.
   */
  // istanbul ignore next: Not important to test
  instanceEnabled = false,
  /**
   * Styling to apply to the prefix text to help you find it in the console..
   */
  styles: Record<string, string> | undefined = undefined,
): Console {
  const prefix = makePrefixedLogger(item, name, styles)

  // Return a Proxy around the console object that supports disabling/filtering
  // logs and outputs correct line numbers if filters are turned off.
  return new Proxy(console, {
    get(target, prop, receiver) {
      return getConsoleProperty(
        item,
        target,
        prop,
        receiver,
        instanceEnabled,
        (_p, l) => {
          return new Proxy(item[prop], {
            apply: (target: any, thisArg: any, argArray: any[]) => {
              const doLog = () => {
                state.storage &&
                  state.storage.append(prop, styles, prefix, ...argArray)
                return Reflect.apply(target, thisArg, argArray)
              }

              filterLogEvent(doLog, l, prefix, argArray)
            },
          })
        },
        /* istanbul ignore next */
        () => Reflect.get(target, prop, receiver),
      )
    },
  })
}
