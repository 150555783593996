import React from 'react'

import { useForegroundColor } from '~/theme'
import {
  HeartbeatAnimationState,
  HeartbeatAnimationTypes,
  Logomark,
  LogomarkProps,
} from '../../../animation/logomark/'
import { ForegroundColorValues } from '~/theme'

export interface HeartbeatLoaderProps extends LogomarkProps {
  /**
   * The current playing state.
   *
   * - playing: The heartbeat animation will loop.
   * - paused: The heartbeat animation will pause where it is.
   * - stopped: The hearbeat animation will play to the end of the loop and then stop.
   */
  state?: HeartbeatAnimationState
  /**
   * Show the Apartment Snapshot house as well.
   */
  showHouse?: boolean
  /**
   * The color of the heartbeat and house. This will resolve
   * theme colors for you.
   */
  color?: string
  /**
   * The Animation style to use for the heartbeat line path:
   * - 'pulse': Uses a short line segment that looks like a pulse monitor.
   * - 'full': Uses a long line segment so the full heartbeat line is visible.
   */
  heartbeatType?: HeartbeatAnimationTypes
  /**
   * The duration of the animation. This is intended for testing so
   * you can disable animations.
   */
  duration?: number
  /**
   * This will force the state into 'paused' and should be used to prevent
   * animations during testing.
   */
  disableAnimations?: boolean
  /**
   * A callback for when the animation is in the 'stopped' state and hits the
   * end of the loop. This allows you to remove it from the render tree after
   * the animation has completed.
   */
  onStopped?: () => void
  /**
   * The loader SVG width
   */
  width?: number
  /**
   * The loader SVG height
   */
  height?: number
}

/**
 * `<HeartbeatLoader>` uses the Apartment Snapshot logo mark (house icon)
 * as a loader where the heartbeat path is animated. You can show or hide
 * the house part of the logo, set the logo color and play/pause/stop the
 * animation.
 */
export const HeartbeatLoader = ({
  state = 'playing',
  color: colorProp = 'primary',
  duration = 2000,
  width = 100,
  height = 56,
  heartbeatType = 'full',
  ...rest
}: HeartbeatLoaderProps) => {
  const color =
    useForegroundColor(colorProp as ForegroundColorValues) || colorProp

  return (
    <Logomark
      testID="HeartbeatLoader"
      width={width}
      height={height}
      color={color}
      duration={duration}
      state={state}
      heartbeatType={heartbeatType}
      {...rest}
    />
  )
}
