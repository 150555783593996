import * as React from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'
import { env } from '~/env'
import { HeartbeatPath, HousePath } from './Path'
import { HEARTBEAT_PATH, HOUSE_PATH } from './path-defintions'
import { HeartbeatAnimationState, HeartbeatAnimationTypes } from './types'

export type LogomarkProps = SvgProps & {
  duration?: number
  heartbeatType?: HeartbeatAnimationTypes
  showHouse?: boolean
  disableAnimations?: boolean
  state?: HeartbeatAnimationState
  onStopped?: () => void
}

interface StaticHeartbeatPathProps extends SvgProps {
  state?: HeartbeatAnimationState
  onStopped?: () => void
}

/**
 * <StaticHeartbeatPath>
 */
export function StaticHeartbeatPath({
  onStopped,
  state,
  ...rest
}: StaticHeartbeatPathProps) {
  React.useEffect(() => {
    if (state === 'stopped' && onStopped) {
      onStopped()
    }
  }, [state, onStopped])
  return <Path d={HEARTBEAT_PATH} fill="none" strokeWidth={3} {...rest} />
}

/**
 * Animated version of our logo mark. The house shape can be shown/hidden and
 * will transition in when shown. The heartbeat path can be animated as a quick
 * pulse or as the full heartbeat line.
 */
function LogomarkSVG(
  {
    duration,
    color,
    heartbeatType,
    showHouse = false,
    state,
    onStopped,
    disableAnimations = env.test,
    ...props
  }: LogomarkProps,
  svgRef?: React.Ref<Svg>,
) {
  return (
    <Svg
      viewBox="0 0 53 56"
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      strokeMiterlimit={10}
      ref={svgRef}
      {...props}
    >
      {disableAnimations ? (
        <StaticHeartbeatPath
          stroke={color}
          onStopped={onStopped}
          state={state}
        />
      ) : (
        <HeartbeatPath
          color={color}
          duration={duration}
          animation={heartbeatType}
          state={state}
          onStopped={onStopped}
        />
      )}
      {showHouse &&
        (disableAnimations ? (
          <Path d={HOUSE_PATH} fill={color} fillRule="nonzero" />
        ) : (
          <HousePath color={color} duration={duration} />
        ))}
    </Svg>
  )
}

const ForwardRef = React.forwardRef(LogomarkSVG)
export const Logomark = React.memo(ForwardRef)
