import { safeGetter } from './functions'

type ListOfGenerateCallback<T> = (i: number, total: number) => T

/**
 * Type Guard and filter to remove `undefined | null` values from an array.
 *
 * @example
 *     ['bar', 'foo', undefined, null]
 *       // Remove nulls
 *       .filter(removeNulls)
 *       // a & b are guaranteed to exist and Typscript gets it!
 *       .sort((a, b) => { ... })
 */
export const removeNulls = <S>(value: S | undefined): value is S =>
  value != null

/**
 * Generate a list of items, calling a factory function
 * to generate each item.
 * @param [count] - The number of items to generate.
 * @param [generate] - The factory used to create
 *   the item. It will be called with the current index in the list.
 *   If the factory is not passed, then the index is returned as
 *   the generated item.
 * @return The list of generated items.
 */
export function listOf<T>(
  count = 1,
  generate: ListOfGenerateCallback<T> = (i: number) => i as T,
): T[] {
  const out = []
  for (let i = 0; i < count; i++) {
    out.push(generate(i, count))
  }
  return out
}

export type SortDirection = 1 | -1

function scoreType(value: unknown) {
  switch (typeof value) {
    case 'number':
      if (isNaN(value)) return -1
    case 'string':
    case 'boolean':
      return 1
    default:
      return -1
  }
}

/**
 * A `Boolean()` equivalent that treats the string "false" as the type false.
 */
function Bool(value: unknown) {
  if (value === 'false') return false
  return Boolean(value)
}

/**
 * A default sort algorithm that will sort numbers, strings and
 * undefined/null in natural order. It also allows sorting comparable types
 * (number, string, boolean) against each other using the following rules:
 *
 * - number vs string: convert numbers to strings then sort as strings
 * - number vs boolean: convert booleans to numbers and then sort as numbers
 * - string vs boolean: convert strings to booleans, then both to numbers and
 *   then sort as numbers. The string "false" is a special case and treated like
 *   the boolean false.
 *
 * You can reverse the sort direction by passing -1 as `direction`.
 */
export function genericSort(
  aVal: unknown,
  bVal: unknown,
  direction: SortDirection = 1,
): number {
  if (scoreType(aVal) === scoreType(bVal)) {
    // strings, numbers (excluding NaN) and booleans
    if (scoreType(aVal) > 0) {
      // Compare booleans to booleans/strings
      if (
        (typeof aVal === 'boolean' && typeof bVal === 'boolean') ||
        (typeof aVal === 'boolean' && typeof bVal === 'string') ||
        (typeof aVal === 'string' && typeof bVal === 'boolean')
      ) {
        return (Number(Bool(aVal)) - Number(Bool(bVal))) * direction
      }
      // Compare numbers to numbers/booleans
      else if (
        (typeof aVal === 'number' && typeof bVal === 'number') ||
        (typeof aVal === 'boolean' && typeof bVal === 'number') ||
        (typeof aVal === 'number' && typeof bVal === 'boolean')
      ) {
        return (Number(aVal) - Number(bVal)) * direction
      }
      // Compare strings
      return aVal.toString().localeCompare(String(bVal)) * direction
    } else {
      return 0
    }
  }
  // B is a non-comparable type and should be sorted last
  else if (scoreType(aVal) > scoreType(bVal)) {
    return -1
  }
  // A is a non-comparable type and should be sorted last
  else if (scoreType(aVal) < scoreType(bVal)) {
    return 1
  }

  return 0
}

/**
 * Generate a ranking of the given items such that tied items are grouped
 * together. This will return a `T[][]` where the outer array is the ranking (1,
 * 2, 3, etc) and the inner array are the list of items that tied for the same
 * rank. Both levels of items are sorted based on the data returned from the two
 * getter parameters.
 */
export function rankItems<T>(
  /**
   * The list of items to rank.
   */
  data: undefined | T[],
  /**
   * A getter that will return the data used to rank items against each other.
   */
  getValue: (v: T) => unknown,
  /**
   * A getter that will return the value used to sort the ties.
   */
  getName: (v: T) => unknown,
  /**
   * The direction to sort itmes.
   */
  direction: SortDirection = -1,
) {
  if (!data?.length) return [] as T[][]

  // Group the items by their `rankKey` value
  const grouped = data.reduce((acc, curr) => {
    const value = safeGetter(curr, getValue)

    if (!acc.has(value)) acc.set(value, [curr])
    else {
      acc.get(value).push(curr)
    }

    return acc
  }, new Map<any, T[]>())

  // Convert the grouped data into a T[][]
  return (
    Array.from(grouped)
      .map((item) => item[1])
      // Sort the rankings by the rankKey descending (this assumes larger scores
      // are better).
      .sort((a: T[], b: T[]) =>
        genericSort(
          safeGetter(a[0], getValue),
          safeGetter(b[0], getValue),
          direction,
        ),
      )
      // Sort the inner arrays of ties ascending.
      .map((position: T[]) =>
        position.sort((a, b) =>
          genericSort(safeGetter(a, getName), safeGetter(b, getName)),
        ),
      )
  )
}
