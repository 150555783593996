import { env } from '~/env'
import { MemoryLogStorage } from './storage'
import { LogLevel, LogStorage, LoggingState } from './types'
import { SQLiteLogStorage } from './storage/sqlite'

// istanbul ignore next: Not important to test
function createLogStorage() {
  switch (env.logStorage) {
    case 'SQLITE':
      // On Web, this will actually be a MemoryLogStorage
      return new SQLiteLogStorage() as LogStorage
    case 'MEMORY':
      return new MemoryLogStorage() as LogStorage
    default:
      return undefined
  }
}

export const state: LoggingState = {
  blacklist: [],
  whitelist: [],
  disabled: !env.verbose,
  level: LogLevel.DEBUG,
  // Storage needs to be configured outside of the React lifecycle so it can be
  // called from background tasks which also are configured outside of the React
  // lifecycle.
  storage: createLogStorage(),
}

/**
 * TESTING ONLY:
 * Allows setting the storage mechanism at runtime.
 */
export function setStorage(s: LogStorage) {
  state.storage = s
}
