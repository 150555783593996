export enum LogLevel {
  DEBUG = 0,
  ERROR = 1,
}

export type BlacklistFilter = string | RegExp

export interface LoggingState {
  blacklist: BlacklistFilter[]
  whitelist: BlacklistFilter[]
  disabled: boolean
  level: LogLevel
  storage: LogStorage
}

export interface LogEvent {
  timestamp: number
  data: any[]
  style: LogStyle
  type: string
}

export type LogStyle = React.CSSProperties

export interface LogFilter {
  level?: string
  filter?: string | RegExp
  startTime?: number
  endTime?: number
}

export interface LogStorage {
  /**
   * Record a log event. If logging is disabled, this is a no-op.
   */
  append(
    /**
     * The type of message such as 'info', 'error', 'group', etc
     */
    type: string | symbol,
    /**
     * The style associated with the logger that created this message.
     */
    style: LogStyle,
    /**
     * The data being logged. This will be the same list of parameters passed to
     * the `console.log` functions. For example, `console.log('foo', 7, true)`
     * should call through to this function as `storage.append('log', 'foo', 7,
     * true)`
     */
    ...data: any[]
  ): Promise<void>

  /**
   * Get all runtime log events
   */
  getLogs(filter: LogFilter): Promise<readonly LogEvent[]>

  /**
   * Clear all logs
   */
  clear(): Promise<void>
}
