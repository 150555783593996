import { env } from '~/env'
import { makeProxyLogger } from './ProxyLogger'
import { makeSimpleLogger } from './SimpleLogger'
import { Platform } from 'react-native'
import { LogEvent, LogFilter, LogLevel } from './types'
import { state } from './state'

/**
 * Get logs matching any search criteria.
 */
export function getLogs(filter?: LogFilter): Promise<readonly LogEvent[]> {
  return state.storage ? state.storage.getLogs(filter) : Promise.resolve([])
}

/**
 * Clear the log storage backend.
 */
export function clearLogs() {
  state.storage && state.storage.clear()
}

/**
 * Globally disable all loggers.
 */
export function disableLogs() {
  state.disabled = true
}
/**
 * Globally enable all loggers.
 */
export function enableLogs() {
  state.disabled = false
}
/**
 * Globally set the log level. The log level should only be changed during
 * development or testing.
 *
 * FYI, setting a log level higher than DEBUG will break the line numbers
 * reported in the console.
 */
export function setLogLevel(l: LogLevel) {
  state.level = l
}
/**
 * Add a filter to the global logging blacklist. The filter should be a string or RegExp
 * that will prevent matching logs from being output. The `whitelist` takes
 * priority over the `blacklist`.
 *
 * Filters should only be used during development or testing.
 *
 * FYI, setting global filters will break the line numbers reported in the
 * console.
 */
export function addLogBlacklist(filter: string | RegExp) {
  state.blacklist.push(filter)
}
/**
 * Remove a filter from the global log filter.
 */
export function removeLogBlacklist(filter: string | RegExp) {
  state.blacklist = state.blacklist.filter((f) => f !== filter)
}
/**
 * Add an item to the logging whitelist. If there is anything in the whitelist,
 * then all logs are filtered unless they match one of the whitelist filters.
 *
 * Filters should only be used during development or testing.
 */
export function addLogWhitelist(filter: string | RegExp) {
  state.whitelist.push(filter)
}
/**
 * Remove an item from the global whitelist. If the whitelist becomes empty
 * after removing the last item, then we revert back to using the blacklist.
 */
export function removeLogWhitelist(filter: string | RegExp) {
  state.whitelist = state.whitelist.filter((f) => f !== filter)
}
/**
 * Clear the global filters which will cause all logs to be displayed.
 */
export function clearLogFilters() {
  state.blacklist = []
  state.whitelist = []
}

/**
 * Create a log object that will console log with the given prefix and styling.
 * You can globally enable/disable logging or filter log output by calling one
 * of the other methods defined in this file (ex. `disableLogs()`).
 */
export const makeLogger = (
  /**
   * The name to use in the loging prefix. For example, passing "Foo" will
   * prefix all logs with "[Foo]".
   */
  name: string,
  /**
   * The color that the log prefix will be.
   */
  color: string,
  /**
   * True = force logging for this instance even if global logging is turned
   * off.
   */
  debug = env.verbose,
) => {
  if (Platform.OS === 'web' || env.test) {
    return makeProxyLogger({}, name, debug, { color, 'font-weight': 'bold' })
  } else {
    /* istanbul ignore next: always use the simple logger during the tests */
    return makeSimpleLogger({}, name, debug, { color, 'font-weight': 'bold' })
  }
}
