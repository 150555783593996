import foreground from './palettes/foreground'

export default {
  default: foreground.default,
  normal: foreground.normal,
  light: foreground.light,
  faded: foreground.faded,
  disabled: foreground.disabled,
  divider: foreground.divider,
  border: foreground.border,
}
