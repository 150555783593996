import React from 'react'
import {
  createTheme,
  ThemeProvider as RestyleThemeProvider,
} from '@shopify/restyle'

import backgroundColors from './backgrounds'
import foregroundColors from './foregrounds'
import { lightConfig } from '../light'

export const dark = createTheme({
  ...lightConfig,
  backgroundColors,
  foregroundColors,
  isDark: true,
})
export type DarkTheme = typeof dark

export interface DarkThemeProviderProps {
  theme?: DarkTheme
  children: React.ReactNode
}

export const DarkThemeProvider = (props: DarkThemeProviderProps) => (
  <RestyleThemeProvider theme={dark} {...props} />
)
