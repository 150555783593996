/**
 * This is the discovery document returned from
 * our auth-discovery endpoint (https://YOUR_DOMAIN/.well-known/openid-configuration).
 * See: https://auth0.com/docs/configure/applications/configure-applications-with-oidc-discovery
 */
// TODO Get this during build time?
export const DISCOVERY = {
  // This is not part of the actual discovery structure
  // but I'm adding it here for ease of use.
  logout_url: 'https://<DOMAIN>/v2/logout',

  // Standard OAuth discovery structure:
  issuer: 'https://<DOMAIN>/',
  authorization_endpoint: 'https://<DOMAIN>/authorize',
  token_endpoint: 'https://<DOMAIN>/oauth/token',
  device_authorization_endpoint: 'https://<DOMAIN>/oauth/device/code',
  userinfo_endpoint: 'https://<DOMAIN>/userinfo',
  mfa_challenge_endpoint: 'https://<DOMAIN>/mfa/challenge',
  jwks_uri: 'https://<DOMAIN>/.well-known/jwks.json',
  registration_endpoint: 'https://<DOMAIN>/oidc/register',
  revocation_endpoint: 'https://<DOMAIN>/oauth/revoke',
  scopes_supported: [
    'openid',
    'profile',
    'offline_access',
    'name',
    'given_name',
    'family_name',
    'nickname',
    'email',
    'email_verified',
    'picture',
    'created_at',
    'identities',
    'phone',
    'address',
  ],
  response_types_supported: [
    'code',
    'token',
    'id_token',
    'code token',
    'code id_token',
    'token id_token',
    'code token id_token',
  ],
  code_challenge_methods_supported: ['S256', 'plain'],
  response_modes_supported: ['query', 'fragment', 'form_post'],
  subject_types_supported: ['public'],
  id_token_signing_alg_values_supported: ['HS256', 'RS256'],
  token_endpoint_auth_methods_supported: [
    'client_secret_basic',
    'client_secret_post',
  ],
  claims_supported: [
    'aud',
    'auth_time',
    'created_at',
    'email',
    'email_verified',
    'exp',
    'family_name',
    'given_name',
    'iat',
    'identities',
    'iss',
    'name',
    'nickname',
    'phone_number',
    'picture',
    'sub',
  ],
  request_uri_parameter_supported: false,
}
