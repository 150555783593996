import { Platform } from 'react-native'
import { Text, TextProps } from './Text'
import { TextWithoutVariantProps as TextBaseProps } from './TextVariants'
import React from 'react'
import { WithOptionalKeys } from 'tsdef'

export interface HeadingProps extends TextBaseProps {
  /**
   * The display size of this heading.
   */
  level: 1 | 2 | 3
  /**
   * The `aria-level` of this heading within the page hiearchy. This prop is
   * independent of the display level so the design does not need to be
   * explicitly tied to the content heirarchy.
   *
   * This prop is important for letting screen readers know the importance of
   * each title on the page.
   *
   * See https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-level
   */
  'aria-level'?: 1 | 2 | 3 | 4 | 5 | 6
}

/**
 * A page or section title. Use the `level` prop to specify the display size of
 * the heading and the `aria-level` to set the accessibility level
 * independeintly.
 */
export function Heading({
  level,
  'aria-level': ariaLevel = level,
  ...rest
}: HeadingProps) {
  let variant: TextProps['variant']
  if (!variant) {
    switch (level) {
      case 1:
        variant = 'h1'
        break
      case 2:
        variant = 'h2'
        break
      default:
        variant = 'h3'
        break
    }
  }

  return (
    <Text
      variant={variant}
      role="heading"
      accessibilityRole="header"
      {...Platform.select({
        web: {
          'aria-level': ariaLevel,
        },
      })}
      {...rest}
    />
  )
}

export type HeadingShortcutProps = WithOptionalKeys<HeadingProps, 'level'>

export const H1 = ({
  level = 1,
  responsive,
  ...props
}: HeadingShortcutProps & {
  responsive?: boolean
}) => (
  <Heading
    level={level}
    fontSize={responsive ? { phone: 21, tablet: 34 } : undefined}
    {...props}
  />
)

export const H2 = ({ level = 2, ...props }: HeadingShortcutProps) => (
  <Heading level={level} {...props} />
)
export const H3 = ({ level = 3, ...props }: HeadingShortcutProps) => (
  <Heading level={level} {...props} />
)
