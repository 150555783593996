import React from 'react'
import { Platform } from 'react-native'

import { Text, TextRef, type TextProps } from '../../text'
import { type ActionStylingProps, genericLinkProps } from './actionStyles'
import { Button, type ButtonProps, type ButtonRef } from './Button'
import type { InnerActionProps } from './types'

/**
 * The props necessary to render the `Text` which is styled to look like a
 * button.
 */
type TextStyledLikeButtonProps = TextProps &
  ActionStylingProps &
  InnerActionProps
/**
 * A type representing either a `Button` or a `Text` styled like a button.
 */
export type TextOrButtonProps = TextStyledLikeButtonProps | ButtonProps
export type TextOrButtonRef = TextRef | ButtonRef

/**
 * Render either a `Text` component on web or a `Button` component on native.
 * This allows us to pass arbitrary children to link elements on native since
 * the React Native `Text` can only handle strings.
 */
export const TextOrButton = React.forwardRef<
  TextOrButtonRef,
  TextOrButtonProps
>(({ hasChildren, href, onPress, ...rest }, ref) => {
  if (Platform.OS === 'web') {
    // On web we want to render a link
    return (
      <Text
        onPress={onPress}
        {...genericLinkProps({
          href,
          hasChildren,
          ...rest,
        })}
        ref={ref}
      />
    )
  } else {
    return (
      <Button
        // Override these default set by `Button`.
        look="link"
        role="link"
        accessibilityRole="link"
        // Pass everything else through to Button which will call
        // `genericButtonProps` internally.
        {...rest}
        onPress={onPress}
        hasChildren={hasChildren}
        ref={ref}
      />
    )
  }
})
