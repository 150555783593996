export const fontFamilies = {
  title: 'SFProDisplay',
  body: 'SFProDisplay',
  mono: 'SFMono',
}

export const fontWeights = {
  normal: 'Regular',
  '100': 'Regular',
  '200': 'Regular',
  '300': 'Medium',
  '400': 'Medium',
  '500': 'Medium',
  '600': 'Semibold',
  '700': 'Semibold',
  '800': 'Bold',
  '900': 'Bold',
  bold: 'Bold',
}

// TODO After we upgrade to Expo 50, we should be able to load the fonts using a
// config plugin which in turn should give us the ability to configure font
// weight and font family using separate props (on iOS and Web) without a custom
// component.
// https://docs.expo.dev/develop/user-interface/fonts/#use-a-google-font
export const textVariants = {
  h1: {
    fontFamily: 'title',
    fontWeight: 'bold',
    fontSize: 34,
  },
  h2: {
    fontFamily: 'title',
    fontWeight: 'bold',
    fontSize: 21,
  },
  h3: {
    fontFamily: 'title',
    fontWeight: 'bold',
    fontSize: 17,
  },
  subtitle: {
    fontFamily: 'title',
    fontWeight: '600',
    fontSize: 20,
  },
  label: {
    fontFamily: 'title',
    fontWeight: '600',
    fontSize: 16,
  },
  'value-label': {
    fontFamily: 'title',
    fontWeight: '600',
    fontSize: 12,
    textTransform: 'uppercase',
  },
  value: {
    fontFamily: 'title',
    fontWeight: '600',
    fontSize: 24,
  },
  info: {
    fontFamily: 'body',
    fontWeight: 'normal',
    fontSize: 14,
    fontStyle: 'italic',
  },
  input: {
    fontFamily: 'body',
    fontWeight: 'normal',
    fontSize: 16,
  },
  body: {
    fontFamily: 'body',
    fontWeight: 'normal',
    fontSize: 14,
  },
  subscript: {
    fontFamily: 'body',
    fontWeight: 'normal',
    fontSize: 14,
    fontStyle: 'italic',
  },
  defaults: {
    fontFamily: 'body',
    fontWeight: 'normal',
    fontSize: 14,
  },
}
