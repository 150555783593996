import React from 'react'
import { LinearGradient, LinearGradientProps } from 'expo-linear-gradient'
import {
  ResponsiveValue,
  createRestyleComponent,
  useResponsiveProp,
  createRestyleFunction,
} from '@shopify/restyle'

import { useTheme } from '~/theme'
import {
  LightTheme,
  ResponsiveThemeProps,
  backgroundColors,
  positionLocation,
} from '~/theme'
import { BoxProps, BoxRestyleProps, boxRestyleFunctions } from '../box/Box'

/**
 * Alias the `positionStart` prop to `style.start`.
 */
const positionStart = createRestyleFunction({
  property: 'positionStart',
  styleProperty: 'start',
})
/**
 * Alias the `postionEnd` prop to `style.end`.
 */
const positionEnd = createRestyleFunction({
  property: 'positionEnd',
  styleProperty: 'end',
})

export const gradientRestyleFunctions = [
  // Use the same functions as Box except for background color (it doesn't
  // apply) and the positioning functions (we need to customize those).
  ...boxRestyleFunctions.filter(
    (f) => f !== backgroundColors && f !== positionLocation,
  ),
  // Add in the positioning props except for "start" and "end" since those
  // conflict with the versions from LinearGradient.
  ...positionLocation.filter(
    (p) => p.property !== 'start' && p.property !== 'end',
  ),
  // Use our `positionStart/End` aliases in place of `start/end`.
  positionStart,
  positionEnd,
]

type GradientBaseProps = LinearGradientProps &
  Omit<BoxRestyleProps, 'start' | 'end'> &
  ResponsiveThemeProps<'positionStart' | 'positionEnd', BoxProps['start']>

const GradientBase = createRestyleComponent<GradientBaseProps, LightTheme>(
  gradientRestyleFunctions,
  LinearGradient,
)

export type ResponsiveThemePaletteColor = ResponsiveValue<
  keyof LightTheme['colors'],
  LightTheme['breakpoints']
>

export interface GradientProps
  extends Omit<GradientBaseProps, 'colors' | 'start' | 'end'> {
  /**
   * An array of responsive colors from our theme.
   *
   * ```tsx
   * colors={['accent', 'primary']}
   * // or
   * colors={[
   *   {
   *     phone: 'positive.100',
   *     desktop: 'negative.100'
   *   },
   *   'primary'
   * ]}
   * ```
   */
  colors?: ResponsiveThemePaletteColor[]
  /**
   * The `{x,y}` position of the gradient start (equivalent to
   * `LinearGradient.start``)
   */
  gradientStart?: LinearGradientProps['start']
  /**
   * The `{x,y}` position of the gradient end (equivalent to
   * `LinearGradient.end``)
   */
  gradientEnd?: LinearGradientProps['end']
  /**
   * Absolute positioning start (equivalent to `Box.start`).
   */
  start?: BoxProps['start']
  /**
   * Absolute positioning end (equivalent to `Box.end`).
   */
  end?: BoxProps['end']
}

/**
 * Renders a `View` with a gradient background. The `colors` must be colors from
 * the `colors` key in our theme and can be responsive values. `Gradient2` is a
 * `Vew` so you can also pass any props of
 * [View](https://reactnative.dev/docs/view).
 *
 * > **WARNING:** Changing the number of colors in the `colors` prop between renders
 * is not supported. If you need to do that, then use `LinearGradient` directly.
 */
export const Gradient = ({
  colors: colorsProp = ['accent', 'primary'],
  gradientStart = { x: 0.2, y: 0.2 },
  gradientEnd = { x: 0.7, y: 0.7 },
  start,
  end,
  ...rest
}: GradientProps) => {
  const theme = useTheme()
  const colors = colorsProp?.map((color) => {
    // This is a quick way to implement the getter but will fail if the number
    // of colors in the gradient changes between renders. A safer approach would
    // be to re-implement useResponsiveProp in a way that recieves the theme so
    // it's nolonger a hook.
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const c = useResponsiveProp(color)
    return theme.colors[c]
  })

  return (
    <GradientBase
      colors={colors}
      // TODO Make these responsive as well
      start={gradientStart}
      end={gradientEnd}
      positionStart={start}
      positionEnd={end}
      {...rest}
    />
  )
}
