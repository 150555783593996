import React from 'react'
import { useTheme as useThemeBase } from '@shopify/restyle'
import {
  type LightTheme as LightThemeBase,
  LightThemeProvider,
  DarkThemeProvider,
  type LightThemeProviderProps,
  type DarkThemeProviderProps,
} from './themes'

export const useTheme = useThemeBase<LightThemeBase>

/**
 * Switch the current light/dark theme to the opposit theme. This makes it easy
 * to invert the theme for tooltips and menus.
 */
export const InvertTheme = (
  props: LightThemeProviderProps | DarkThemeProviderProps,
) => {
  const theme = useThemeBase()
  /* istanbul ignore next */
  return theme.isDark ? (
    <LightThemeProvider {...props} />
  ) : (
    <DarkThemeProvider {...props} />
  )
}
