// DO NOT MODIFY
// This palette was generated using the online palette generator found at:
// v2:
// http://mcg.mbitson.com/#!?primary=%23f44c7f&accent=%23ff7f40&positive=%2330cb4b&warn=%23fecd00&negative=%23ff5242&grayscale=%23686e7d&secondary=%23283663&themename=mcgtheme#%2F
//
// v1:
// http://mcg.mbitson.com/#!?primary=%23f44c7f&accent=%23ff7f40&positive=%2330cb4b&warn=%23fecd00&negative=%23ff5242&grayscale=%23686e7d&secondary=%23283663&themename=mcgtheme#%2F
//
// To regenerate the palette:
// - Visit the link above
// - Make your changes
// - Export using the "MCG Export" format
// - Replace the URL above with the updated URL from that site
// - Replace the `mcg` variable below with your exported data
//
const mcg = [
  {
    colors: [
      {
        name: '50',
        hex: '#feeaf0',
        darkContrast: true,
      },
      {
        name: '100',
        hex: '#fcc9d9',
        darkContrast: true,
      },
      {
        name: '200',
        hex: '#faa6bf',
        darkContrast: true,
      },
      {
        name: '300',
        hex: '#f782a5',
        darkContrast: true,
      },
      {
        name: '400',
        hex: '#f66792',
        darkContrast: true,
      },
      {
        name: '500',
        hex: '#f44c7f',
        darkContrast: true,
      },
      {
        name: '600',
        hex: '#e23d6f',
        darkContrast: false,
      },
      {
        name: '700',
        hex: '#ca345c',
        darkContrast: false,
      },
      {
        name: '800',
        hex: '#af294b',
        darkContrast: false,
      },
      {
        name: '900',
        hex: '#9a213b',
        darkContrast: false,
      },
      {
        name: 'A100',
        hex: '#ffffff',
        darkContrast: true,
      },
      {
        name: 'A200',
        hex: '#fff2f5',
        darkContrast: true,
      },
      {
        name: 'A400',
        hex: '#ffbfcc',
        darkContrast: true,
      },
      {
        name: 'A700',
        hex: '#ffa6b7',
        darkContrast: true,
      },
    ],
    orig: [
      {
        name: '50',
        hex: '#feeaf0',
        darkContrast: true,
      },
      {
        name: '100',
        hex: '#fcc9d9',
        darkContrast: true,
      },
      {
        name: '200',
        hex: '#faa6bf',
        darkContrast: true,
      },
      {
        name: '300',
        hex: '#f782a5',
        darkContrast: true,
      },
      {
        name: '400',
        hex: '#f66792',
        darkContrast: true,
      },
      {
        name: '500',
        hex: '#f44c7f',
        darkContrast: true,
      },
      {
        name: '600',
        hex: '#e23d6f',
        darkContrast: false,
      },
      {
        name: '700',
        hex: '#ca345c',
        darkContrast: false,
      },
      {
        name: '800',
        hex: '#af294b',
        darkContrast: false,
      },
      {
        name: '900',
        hex: '#9a213b',
        darkContrast: false,
      },
      {
        name: 'A100',
        hex: '#ffffff',
        darkContrast: true,
      },
      {
        name: 'A200',
        hex: '#fff2f5',
        darkContrast: true,
      },
      {
        name: 'A400',
        hex: '#ffbfcc',
        darkContrast: true,
      },
      {
        name: 'A700',
        hex: '#ffa6b7',
        darkContrast: true,
      },
    ],
    base: '#f44c7f',
    json: '',
    name: 'primary',
  },
  {
    colors: [
      {
        name: '50',
        hex: '#fff0e8',
        darkContrast: true,
      },
      {
        name: '100',
        hex: '#ffd9c6',
        darkContrast: true,
      },
      {
        name: '200',
        hex: '#ffbfa0',
        darkContrast: true,
      },
      {
        name: '300',
        hex: '#ffa579',
        darkContrast: true,
      },
      {
        name: '400',
        hex: '#ff925d',
        darkContrast: true,
      },
      {
        name: '500',
        hex: '#ff7f40',
        darkContrast: true,
      },
      {
        name: '600',
        hex: '#f86726',
        darkContrast: true,
      },
      {
        name: '700',
        hex: '#e45117',
        darkContrast: true,
      },
      {
        name: '800',
        hex: '#c84314',
        darkContrast: true,
      },
      {
        name: '900',
        hex: '#ab3512',
        darkContrast: false,
      },
      {
        name: 'A100',
        hex: '#ffffff',
        darkContrast: true,
      },
      {
        name: 'A200',
        hex: '#ffffff',
        darkContrast: true,
      },
      {
        name: 'A400',
        hex: '#ffd7cd',
        darkContrast: true,
      },
      {
        name: 'A700',
        hex: '#ffc2b3',
        darkContrast: true,
      },
    ],
    orig: [
      {
        name: '50',
        hex: '#fff0e8',
        darkContrast: true,
      },
      {
        name: '100',
        hex: '#ffd9c6',
        darkContrast: true,
      },
      {
        name: '200',
        hex: '#ffbfa0',
        darkContrast: true,
      },
      {
        name: '300',
        hex: '#ffa579',
        darkContrast: true,
      },
      {
        name: '400',
        hex: '#ff925d',
        darkContrast: true,
      },
      {
        name: '500',
        hex: '#ff7f40',
        darkContrast: true,
      },
      {
        name: '600',
        hex: '#f86726',
        darkContrast: true,
      },
      {
        name: '700',
        hex: '#e45117',
        darkContrast: true,
      },
      {
        name: '800',
        hex: '#c84314',
        darkContrast: true,
      },
      {
        name: '900',
        hex: '#ab3512',
        darkContrast: false,
      },
      {
        name: 'A100',
        hex: '#ffffff',
        darkContrast: true,
      },
      {
        name: 'A200',
        hex: '#ffffff',
        darkContrast: true,
      },
      {
        name: 'A400',
        hex: '#ffd7cd',
        darkContrast: true,
      },
      {
        name: 'A700',
        hex: '#ffc2b3',
        darkContrast: true,
      },
    ],
    base: '#ff7f40',
    json: '',
    name: 'accent',
  },
  {
    colors: [
      {
        name: '50',
        hex: '#e6f9e9',
        darkContrast: true,
      },
      {
        name: '100',
        hex: '#c1efc9',
        darkContrast: true,
      },
      {
        name: '200',
        hex: '#98e5a5',
        darkContrast: true,
      },
      {
        name: '300',
        hex: '#6edb81',
        darkContrast: true,
      },
      {
        name: '400',
        hex: '#4fd366',
        darkContrast: true,
      },
      {
        name: '500',
        hex: '#30cb4b',
        darkContrast: true,
      },
      {
        name: '600',
        hex: '#25ac3a',
        darkContrast: false,
      },
      {
        name: '700',
        hex: '#1b8f2d',
        darkContrast: false,
      },
      {
        name: '800',
        hex: '#1b7527',
        darkContrast: false,
      },
      {
        name: '900',
        hex: '#0b5813',
        darkContrast: false,
      },
      {
        name: 'A100',
        hex: '#ddffe0',
        darkContrast: true,
      },
      {
        name: 'A200',
        hex: '#aaffb1',
        darkContrast: true,
      },
      {
        name: 'A400',
        hex: '#77ff83',
        darkContrast: true,
      },
      {
        name: 'A700',
        hex: '#5dff6b',
        darkContrast: true,
      },
    ],
    orig: [
      {
        name: '50',
        hex: '#e6f9e9',
        darkContrast: true,
      },
      {
        name: '100',
        hex: '#c1efc9',
        darkContrast: true,
      },
      {
        name: '200',
        hex: '#98e5a5',
        darkContrast: true,
      },
      {
        name: '300',
        hex: '#6edb81',
        darkContrast: true,
      },
      {
        name: '400',
        hex: '#4fd366',
        darkContrast: true,
      },
      {
        name: '500',
        hex: '#30cb4b',
        darkContrast: true,
      },
      {
        name: '600',
        hex: '#25ac3a',
        darkContrast: false,
      },
      {
        name: '700',
        hex: '#1b8f2d',
        darkContrast: false,
      },
      {
        name: '800',
        hex: '#1b7527',
        darkContrast: false,
      },
      {
        name: '900',
        hex: '#0b5813',
        darkContrast: false,
      },
      {
        name: 'A100',
        hex: '#ddffe0',
        darkContrast: true,
      },
      {
        name: 'A200',
        hex: '#aaffb1',
        darkContrast: true,
      },
      {
        name: 'A400',
        hex: '#77ff83',
        darkContrast: true,
      },
      {
        name: 'A700',
        hex: '#5dff6b',
        darkContrast: true,
      },
    ],
    base: '#30cb4b',
    json: '',
    name: 'positive',
  },
  {
    colors: [
      {
        name: '50',
        hex: '#fff9e0',
        darkContrast: true,
      },
      {
        name: '100',
        hex: '#fff0b3',
        darkContrast: true,
      },
      {
        name: '200',
        hex: '#ffe680',
        darkContrast: true,
      },
      {
        name: '300',
        hex: '#fedc4d',
        darkContrast: true,
      },
      {
        name: '400',
        hex: '#fed526',
        darkContrast: true,
      },
      {
        name: '500',
        hex: '#fecd00',
        darkContrast: true,
      },
      {
        name: '600',
        hex: '#e7b600',
        darkContrast: true,
      },
      {
        name: '700',
        hex: '#ce9d00',
        darkContrast: false,
      },
      {
        name: '800',
        hex: '#ad7f00',
        darkContrast: false,
      },
      {
        name: '900',
        hex: '#895e01',
        darkContrast: false,
      },
      {
        name: 'A100',
        hex: '#ffffff',
        darkContrast: true,
      },
      {
        name: 'A200',
        hex: '#fffaf1',
        darkContrast: true,
      },
      {
        name: 'A400',
        hex: '#ffe8be',
        darkContrast: true,
      },
      {
        name: 'A700',
        hex: '#ffdfa4',
        darkContrast: true,
      },
    ],
    orig: [
      {
        name: '50',
        hex: '#fff9e0',
        darkContrast: true,
      },
      {
        name: '100',
        hex: '#fff0b3',
        darkContrast: true,
      },
      {
        name: '200',
        hex: '#ffe680',
        darkContrast: true,
      },
      {
        name: '300',
        hex: '#fedc4d',
        darkContrast: true,
      },
      {
        name: '400',
        hex: '#fed526',
        darkContrast: true,
      },
      {
        name: '500',
        hex: '#fecd00',
        darkContrast: true,
      },
      {
        name: '600',
        hex: '#e7b600',
        darkContrast: true,
      },
      {
        name: '700',
        hex: '#ce9d00',
        darkContrast: false,
      },
      {
        name: '800',
        hex: '#ad7f00',
        darkContrast: false,
      },
      {
        name: '900',
        hex: '#895e01',
        darkContrast: false,
      },
      {
        name: 'A100',
        hex: '#ffffff',
        darkContrast: true,
      },
      {
        name: 'A200',
        hex: '#fffaf1',
        darkContrast: true,
      },
      {
        name: 'A400',
        hex: '#ffe8be',
        darkContrast: true,
      },
      {
        name: 'A700',
        hex: '#ffdfa4',
        darkContrast: true,
      },
    ],
    base: '#fecd00',
    json: '',
    name: 'warn',
  },
  {
    colors: [
      {
        name: '50',
        hex: '#ffeae8',
        darkContrast: true,
      },
      {
        name: '100',
        hex: '#ffcbc6',
        darkContrast: true,
      },
      {
        name: '200',
        hex: '#ffa9a1',
        darkContrast: true,
      },
      {
        name: '300',
        hex: '#ff867b',
        darkContrast: true,
      },
      {
        name: '400',
        hex: '#ff6c5e',
        darkContrast: true,
      },
      {
        name: '500',
        hex: '#ff5242',
        darkContrast: true,
      },
      {
        name: '600',
        hex: '#e54032',
        darkContrast: false,
      },
      {
        name: '700',
        hex: '#cf3024',
        darkContrast: false,
      },
      {
        name: '800',
        hex: '#ab271e',
        darkContrast: false,
      },
      {
        name: '900',
        hex: '#83140e',
        darkContrast: false,
      },
      {
        name: 'A100',
        hex: '#ffffff',
        darkContrast: true,
      },
      {
        name: 'A200',
        hex: '#ffffff',
        darkContrast: true,
      },
      {
        name: 'A400',
        hex: '#ffd0ce',
        darkContrast: true,
      },
      {
        name: 'A700',
        hex: '#ffb7b4',
        darkContrast: true,
      },
    ],
    orig: [
      {
        name: '50',
        hex: '#ffeae8',
        darkContrast: true,
      },
      {
        name: '100',
        hex: '#ffcbc6',
        darkContrast: true,
      },
      {
        name: '200',
        hex: '#ffa9a1',
        darkContrast: true,
      },
      {
        name: '300',
        hex: '#ff867b',
        darkContrast: true,
      },
      {
        name: '400',
        hex: '#ff6c5e',
        darkContrast: true,
      },
      {
        name: '500',
        hex: '#ff5242',
        darkContrast: true,
      },
      {
        name: '600',
        hex: '#e54032',
        darkContrast: false,
      },
      {
        name: '700',
        hex: '#cf3024',
        darkContrast: false,
      },
      {
        name: '800',
        hex: '#ab271e',
        darkContrast: false,
      },
      {
        name: '900',
        hex: '#83140e',
        darkContrast: false,
      },
      {
        name: 'A100',
        hex: '#ffffff',
        darkContrast: true,
      },
      {
        name: 'A200',
        hex: '#ffffff',
        darkContrast: true,
      },
      {
        name: 'A400',
        hex: '#ffd0ce',
        darkContrast: true,
      },
      {
        name: 'A700',
        hex: '#ffb7b4',
        darkContrast: true,
      },
    ],
    base: '#ff5242',
    json: '',
    name: 'negative',
  },
  {
    colors: [
      {
        name: '50',
        hex: '#edeeef',
        darkContrast: true,
      },
      {
        name: '100',
        hex: '#d2d4d8',
        darkContrast: true,
      },
      {
        name: '200',
        hex: '#b4b7be',
        darkContrast: true,
      },
      {
        name: '300',
        hex: '#959aa4',
        darkContrast: true,
      },
      {
        name: '400',
        hex: '#7f8491',
        darkContrast: true,
      },
      {
        name: '500',
        hex: '#686e7d',
        darkContrast: false,
      },
      {
        name: '600',
        hex: '#606675',
        darkContrast: false,
      },
      {
        name: '700',
        hex: '#555b6a',
        darkContrast: false,
      },
      {
        name: '800',
        hex: '#4b5160',
        darkContrast: false,
      },
      {
        name: '900',
        hex: '#3a3f4d',
        darkContrast: false,
      },
      {
        name: 'A100',
        hex: '#a5bcfe',
        darkContrast: true,
      },
      {
        name: 'A200',
        hex: '#7397fd',
        darkContrast: true,
      },
      {
        name: 'A400',
        hex: '#3e71ff',
        darkContrast: false,
      },
      {
        name: 'A700',
        hex: '#255eff',
        darkContrast: false,
      },
    ],
    orig: [
      {
        name: '50',
        hex: '#edeeef',
        darkContrast: true,
      },
      {
        name: '100',
        hex: '#d2d4d8',
        darkContrast: true,
      },
      {
        name: '200',
        hex: '#b4b7be',
        darkContrast: true,
      },
      {
        name: '300',
        hex: '#959aa4',
        darkContrast: true,
      },
      {
        name: '400',
        hex: '#7f8491',
        darkContrast: true,
      },
      {
        name: '500',
        hex: '#686e7d',
        darkContrast: false,
      },
      {
        name: '600',
        hex: '#606675',
        darkContrast: false,
      },
      {
        name: '700',
        hex: '#555b6a',
        darkContrast: false,
      },
      {
        name: '800',
        hex: '#4b5160',
        darkContrast: false,
      },
      {
        name: '900',
        hex: '#3a3f4d',
        darkContrast: false,
      },
      {
        name: 'A100',
        hex: '#a5bcfe',
        darkContrast: true,
      },
      {
        name: 'A200',
        hex: '#7397fd',
        darkContrast: true,
      },
      {
        name: 'A400',
        hex: '#3e71ff',
        darkContrast: false,
      },
      {
        name: 'A700',
        hex: '#255eff',
        darkContrast: false,
      },
    ],
    base: '#686e7d',
    json: '',
    name: 'grayscale',
  },
  {
    colors: [
      {
        name: '50',
        hex: '#e5e7ec',
        darkContrast: true,
      },
      {
        name: '100',
        hex: '#bfc3d0',
        darkContrast: true,
      },
      {
        name: '200',
        hex: '#949bb1',
        darkContrast: true,
      },
      {
        name: '300',
        hex: '#697292',
        darkContrast: false,
      },
      {
        name: '400',
        hex: '#48547a',
        darkContrast: false,
      },
      {
        name: '500',
        hex: '#283663',
        darkContrast: false,
      },
      {
        name: '600',
        hex: '#24305b',
        darkContrast: false,
      },
      {
        name: '700',
        hex: '#1e2951',
        darkContrast: false,
      },
      {
        name: '800',
        hex: '#182247',
        darkContrast: false,
      },
      {
        name: '900',
        hex: '#0f1635',
        darkContrast: false,
      },
      {
        name: 'A100',
        hex: '#7288ff',
        darkContrast: true,
      },
      {
        name: 'A200',
        hex: '#3f5dff',
        darkContrast: false,
      },
      {
        name: 'A400',
        hex: '#0c32ff',
        darkContrast: false,
      },
      {
        name: 'A700',
        hex: '#0026f1',
        darkContrast: false,
      },
    ],
    orig: [
      {
        name: '50',
        hex: '#e5e7ec',
        darkContrast: true,
      },
      {
        name: '100',
        hex: '#bfc3d0',
        darkContrast: true,
      },
      {
        name: '200',
        hex: '#949bb1',
        darkContrast: true,
      },
      {
        name: '300',
        hex: '#697292',
        darkContrast: false,
      },
      {
        name: '400',
        hex: '#48547a',
        darkContrast: false,
      },
      {
        name: '500',
        hex: '#283663',
        darkContrast: false,
      },
      {
        name: '600',
        hex: '#24305b',
        darkContrast: false,
      },
      {
        name: '700',
        hex: '#1e2951',
        darkContrast: false,
      },
      {
        name: '800',
        hex: '#182247',
        darkContrast: false,
      },
      {
        name: '900',
        hex: '#0f1635',
        darkContrast: false,
      },
      {
        name: 'A100',
        hex: '#7288ff',
        darkContrast: true,
      },
      {
        name: 'A200',
        hex: '#3f5dff',
        darkContrast: false,
      },
      {
        name: 'A400',
        hex: '#0c32ff',
        darkContrast: false,
      },
      {
        name: 'A700',
        hex: '#0026f1',
        darkContrast: false,
      },
    ],
    base: '#283663',
    json: '',
    name: 'secondary',
  },
]

export interface Color {
  name: string
  hex: `#${string}`
  darkContrast: boolean
}

export interface Palette {
  50: Color
  100: Color
  200: Color
  300: Color
  400: Color
  500: Color
  600: Color
  700: Color
  800: Color
  900: Color
  A100: Color
  A200: Color
  A400: Color
  A700: Color
}

export interface Palettes {
  primary: Palette
  secondary: Palette
  accent: Palette
  grayscale: Palette
  positive: Palette
  negative: Palette
  warn: Palette
}

// Reformat to something easier to use
export default mcg.reduce((acc, curr) => {
  acc[curr.name] = curr.colors.reduce((a2, c2) => ((a2[c2.name] = c2), a2), {})
  return acc
}, {}) as Palettes
