import palettes from '../../light/palettes/gradient'
import { addAlphaToHexColor as color } from '~/utils'

export default {
  default: color(palettes.secondary[900].hex, 1),
  shaded: color(palettes.secondary[500].hex, 0.8),
  deeplyShaded: color(palettes.secondary[500].hex, 0.9),
  overlay: color(palettes.secondary[500].hex, 0.8),
  fade: 'rgba(0, 0, 0, 0.9)',
  lightlyFade: 'rgba(0, 0, 0, 0.5)',
  input: color(palettes.secondary[500].hex, 0.1),
  hover: color(palettes.secondary[500].hex, 0.2),
  selected: color(palettes.primary[500].hex, 0.4),
}
