import palettes from './gradient'
import { addAlphaToHexColor as color } from '~/utils'

export default {
  // This should be the only color without transparency
  default: color(palettes.secondary[800].hex, 1),
  normal: color(palettes.secondary[600].hex, 1),
  light: color(palettes.secondary[600].hex, 0.8),
  faded: color(palettes.secondary[900].hex, 0.45),
  disabled: color(palettes.grayscale[900].hex, 0.35),
  divider: color(palettes.grayscale[900].hex, 0.1),
  border: color(palettes.grayscale[900].hex, 0.1),
}
