import React from 'react'
import { Platform, Linking, GestureResponderEvent } from 'react-native'

import {
  TextOrButton,
  type TextOrButtonProps,
  type TextOrButtonRef,
} from './TextOrButton'

export type ExternalLinkProps = TextOrButtonProps & {
  href: string
  /**
   * Whether to open the link in an external window. This should be used
   * whenever you want to open an external URL.
   */
  blank?: boolean
  /**
   * TESTING ONLY: Allows verifying that Linking.openURL is called.
   *
   * @deprecated: This should only be used during testing.
   */
  openURL?: (url: string) => void
}

export type ExternalLinkRef = TextOrButtonRef

/**
 * Render a link that will be take the user to an external URL. On web, this renders a
 * `Text2` component which decomposes into an `<a>` tag. On native, this renders
 * a `Button` which decomposes into a React Native `Pressable`.
 */
export const ExternalLink = React.forwardRef<
  ExternalLinkRef,
  ExternalLinkProps
>(
  (
    {
      testID = 'ExternalLink',
      disabled,
      href,
      blank = true,
      onPress,
      openURL = Linking.openURL,
      ...props
    },
    ref,
  ) => {
    return (
      <TextOrButton
        testID={testID}
        disabled={disabled}
        {...(!disabled
          ? Platform.select({
              web: {
                href,
                hrefAttrs: blank
                  ? {
                      target: '_blank',
                      rel: 'noopener noreferrer',
                    }
                  : undefined,
                onPress,
              },
              native: {
                onPress: (e: GestureResponderEvent) => {
                  onPress && onPress(e)
                  if (!e.defaultPrevented) {
                    openURL.call(Linking, href)
                  }
                },
              },
            })
          : {})}
        {...props}
        ref={ref}
      />
    )
  },
)
